<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
         <!-- barra de busqueda -->
         <article class="col-12 pb-2">
          <fieldset class="form-group position-relative has-icon-left m-0">
            <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
            placeholder="Buscar por Folio, Proyecto y Empresa aquí ...">
            <div class="form-control-position">
              <i class="feather ft-search"></i>
            </div>
          </fieldset>
        </article>
        <!-- Tabla de solicitudes  -->
        <div class="row match_height">
          <section id="recent-transactions" class="col-12">
            <article class="card ml-1 mr-1">
              <div class="card-header">
                <h4 class="ml-1">Catálogo de proyectos</h4>
                <div class="heading-elements invoice">
                    <div class="d-inline-block">
                        <button class="btn btn-md clr round btn-min-width pull-right mr-1" (click)="openCreate()" id="dropdownBasic1">
                            <i class="feather ft-plus"></i> Nuevo Proyecto
                        </button>
                    </div>
                </div>
              </div>
              <section class="card-body">
                <div class="card-dashboard col-12 table-responsive-md">
                    <ngx-datatable class="bootstrap table-bordered" [limit]="30" [rows]="contenido" [columnMode]="'flex'"
                      [headerHeight]="50" [footerHeight]="40" [rowHeight]="50" fxFlex="auto">
                      <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [width]="'auto'" [flexGrow]="1">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div class="w-100 center-items">{{value}}</div> 
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Proyecto" prop="proyecto" [width]="'auto'" [flexGrow]="2">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div class="w-100 center-items">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Empresa" prop="nombre-empresa" [width]="'auto'" [flexGrow]="2">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div class="w-100 center-items">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="1" headerClass="text-center">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <div class="w-100 center-items">
                              <i class="font-medium-2 feather ft-edit-2 info mr-1 cursor" title="Editar" (click)="editar(row)"></i>
                              <i class="font-medium-2 la la-trash danger cursor" title="Eliminar" (click)="borrar(row)"></i>
                            </div>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
              </section>
            </article>
          </section>
        </div>
      </div>
    </div>
</div>
<admin-modals [info]="selectedRow" [labels]="modalLabels"></admin-modals>
        <!-- Modal -->
        <ng-template class="modal text-left" #ModalNewProject let-c="close" let-d="dismiss">
          <div class="modal-lg">
            <div class="modal-header">
              <h4 id="myModalLabel23">Nuevo Proyecto</h4>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
                <span class="icon-close-md" aria-hidden="true">&times;</span>
              </button>

            </div>
            <!-- titulos del modal en info -->
              <div class="p-2">
                <div class="px-1 pb-2 row mrg" matchHeight="card">
                  <label for="Descripcion">Empresa</label>
                  <input id="NewProjectEmpresa" class="form-control"
                    placeholder="Nombre de la empresa" required>
                </div>
                <div class="px-1 pb-2 row mrg" matchHeight="card">
                  <label for="Descripcion">Proyecto</label>
                  <input id="NewProjectProyecto" class="form-control"
                    placeholder="Nombre del proyecto" required>
                </div>
                <div class="form-actions text-right">
                  <button type="submit" class="btn clr" (click)="postProyecto($event)">
                    <i class="la la-check"></i> Guardar
                  </button>
                </div>
              </div>
          </div>
        </ng-template>
