
<div class="app-content content pl-1">
  <div class="content-body">
    <!-- inicia chat   -->
    <div class=" row email-application">
      <div class="app-content content w-100">
        <div class="sidebar-left" id="sidebar-left">
          <div class="sidebar">
            <div class="sidebar-content email-app-sidebar d-flex">
              <!-- sidebar close icon -->
              <span class="sidebar-close-icon" (click)="showSidebar($event)">
                <i class="ficon feather ft-x"></i>
              </span>
              <!-- sidebar close icon -->
              <div class="email-app-menu">
                <div class="form-group form-group-compose">
                  <!-- compose button  -->
                  <button type="button" class="btn clr-btn btn-glow btn-block my-2 compose-btn" id="compose-btn"
                    (click)="enviarMensajeModal(ModalEnviarMensaje)">
                    <i class="ficon feather ficon feather ft-plus"></i>
                    Nuevo Mensaje
                  </button>
                </div>
                <div class="sidebar-menu-list" fxFlex="auto">
                  <!-- sidebar menu  -->
                  <article class="col-12 p-0">
                    <div class="row">
                      <div class="col-12 p-0 pl-1">
                        <div class="list-group">
                          <div class="list-group-item  flex-column align-items-start">
                            <h4 class="">Folio</h4>
                            <p class="clr-txt">{{solicitudInfo.folio}}</p>
                            <h4 class="">Cliente</h4>
                            <p class="clr-txt">{{solicitudInfo.cliente['title']}}</p>
                            <h4 class="">Proyecto</h4>
                            <p class="clr-txt">{{solicitudInfo.proyecto}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <!-- User new mail right area -->
            <div class="compose-new-mail-sidebar" id="compose-sidebar" fxFlex="auto">
              <div class="card mb-0 shadow-none quill-wrapper p-0">
                <div class="card-header">
                  <h3 class="card-title" id="emailCompose">Nuevo Mensaje</h3>
                  <button type="button" class="close close-icon" id="showCompose" (click)="showCompose($event)">
                    <i class="ficon feather ft-x"></i>
                  </button>
                </div>
                <!-- form start -->
                <form action="" id="compose-form" [formGroup]="newRevisionForm" (ngSubmit)="onSubmit()">
                  <div class="card-content">
                    <div class="card-body pt-0">
                      <div class="form-group pb-50">
                        <label for="emailfrom">Para</label>
                        <input type="text" id="emailfrom" class="form-control" [value]="cliente" disabled>
                      </div>
                      <div class="form-label-group mb-1">
                        <quill-editor class="content-podio" id="txtContent" [modules]="modulesQuill"
                                formControlName="comment">
                                <div quill-editor-toolbar>
                                    <span class="ql-formats">
                                        <button type="button" class="ql-bold" title="Bold">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-stroke"
                                                    d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                </path>
                                                <path class="ql-stroke"
                                                    d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-italic">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line>
                                                <line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-underline">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-stroke"
                                                    d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path>
                                                <rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3"
                                                    y="15"></rect>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">

                                        <button type="button" class="ql-header" value="1">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-fill"
                                                    d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-header" value="2">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-fill"
                                                    d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z">
                                                </path>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">

                                        <button type="button" class="ql-list" value="ordered">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line>
                                                <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5">
                                                </line>
                                                <path class="ql-fill"
                                                    d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z">
                                                </path>
                                                <path class="ql-stroke ql-thin"
                                                    d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156">
                                                </path>
                                                <path class="ql-stroke ql-thin"
                                                    d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-list" value="bullet">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">
                                        <select class="ql-align" [title]="'Aligment'">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                                </div>
                            </quill-editor>
                      </div>
                      <div *ngIf="submitted && fNewComment.comment.errors " class="ml-2 invalid-feedback">
                        <div *ngIf="remember.comment.errors?.required">Este campo es requerido*</div>
                      </div>

                      <div class="form-group mt-2">
                        <div class="custom-file">
                          <input [ngClass]="{ 'is-invalid': submitted && fNewComment.file.errors }"
                            formControlName="file" type="file" accept=".png, .jpg, .jpeg" class="custom-file-input"
                            id="emailAttach" (change)="onFileChange($event)">
                          <label class="custom-file-label" for="emailAttach">{{fNewComment.file.value}}</label>
                          <div *ngIf="submitted && fNewComment.comment.errors " class="ml-2 invalid-feedback">
                            <div *ngIf="fNewComment.file.errors?.required">Este campo es requerido*</div>
                          </div>
                          <div *ngIf="submitted && fNewComment.comment.errors " class="ml-2 invalid-feedback">
                            <div *ngIf="fNewComment.file.errors?.ext">Archivo invalido*</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-0 d-flex justify-content-end pt-0">
                    <button type="reset" class="btn btn-danger cancel-btn mr-1" (click)="showCompose($event)">
                      <span class="d-sm-inline d-none">Cancelar</span>
                    </button>
                    <button type="submit" class="btn-send btn btn-info">
                      <span class="d-sm-inline d-none">Enviar</span>
                    </button>
                  </div>
                </form>
                <!-- form start end-->
              </div>
            </div>
            <!--/ User Chat profile right area -->
          </div>
        </div>
        <div class="content-right">
          <div class="content-header row">
          </div>
          <div class="content-overlay"></div>
          <div class="content-wrapper">
            <div class="content-body">
              <!-- email app overlay -->
              <div class="app-content-overlay" id="app-content-overlay" (click)="showCompose($event)"
                (click)="showSidebar($event)"></div>
              <div class="email-app-area">
                <!-- Email list Area -->
                <div class="email-app-list-wrapper">
                  <div class="email-app-list">
                    <div class="mt-2 sidebar-toggle col-md-1 d-block d-md-inline-block d-lg-none"
                      (click)="showSidebar($event)">
                      <i class="ficon feather ft-align-justify font-large-1"></i>
                    </div>
                    <div class="email-action align-items-center justify-content-between">
                      <h3 class="text-center clr-txt">Seguimiento del soporte</h3>
                      <button type="button" class=" btn clr-btn btn-glow  my-2" (click)="calificar()" id="calificar-btn">
                        Finalizar Soporte
                      </button>
                    </div>
                    <div class="info-mobile">
                      <div>
                        <h4 class="">Folio</h4>
                        <p class="clr-txt">{{solicitudInfo.folio}}</p>
                      </div>
                      <div>
                        <h4 class="">Cliente</h4>
                        <p class="clr-txt">{{solicitudInfo.cliente['title']}}</p>
                      </div>
                      <div>
                        <h4 class="">Proyecto</h4>
                        <p class="clr-txt">{{solicitudInfo.proyecto}}</p>
                      </div>
                    </div>
                    <div class="align-btns">
                      <button type="button" class="btn clr-btn btn-glow my-2 compose-btn"
                        (click)="enviarMensajeModal(ModalEnviarMensaje)">
                        <i class="ficon feather ficon feather ft-plus"></i>
                       Nuevo Mensaje
                      </button>
                      <button type="button" class=" btn clr-btn btn-glow  my-2" (click)="calificar()">
                        Finalizar Soporte
                      </button>
                    </div>
                    
                  
                    <div class="email-user-list list-group">
                      <ul class="users-list-wrapper media-list" *ngIf="firstMessage">
                        <!-- PRIMER COMENTARIO -->
                        <li [ngClass]="user2==true?'bg-gray':''" class="card-body py-2">
                          <div class="d-flex">
                              <div class="chat-item__avatar d-flex" >
                                <div class="avatar">
                                  <img src="../../../../assets/user.svg" alt="avtar img holder">
                                </div>
                                <div>
                                 <span style="margin-left: 5px;"> {{firstMessage['user']['title']}} </span>
                                   <p class="truncate mb-0" style="font-size: 10px; margin-left: 5px;">
                                     {{firstMessage['fecha']}}
                                   </p>
                                </div>
                             </div>
                          </div>
                          <div class="media-body d-flex align-items-center" (click)="showEmail($event, firstMessage,'1')">
                            <div class="mr-1 ml-1" style="font-size: 13px; text-align: justify;" [innerHTML]="firstMessage['mensaje']">
                              
                            </div>
                            <div class="chat-item__file col-3 px-0" id="img-mobile">
                              <ngb-carousel [pauseOnHover]="true" interval="1500">
                                <ng-template ngbSlide *ngFor="let image of imagesUrlsExistente">
                                  <div>
                                    <img [src]="image" alt="img25" height="120" width="140">
                                  </div>
                                </ng-template>
                              </ngb-carousel>
                            </div>
                          </div>
                        </li>
                        <!-- CHAT -->
                        <li class="card-body py-2" *ngFor="let chat of conversacion">
                          <div style="display: flex;">
                          <div ngbDropdown class="d-inline-block mt-1" *ngIf="chat.user.item_id==solicitudInfo.consultor.item_id">
                            <i class="mr-1 feather ft-more-vertical font-large-1 cursor" id="dropdownBasic6"
                              ngbDropdownToggle></i>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic6">
                              <button class="dropdown-item" type="button" (click)="agregarImg(chat)"><i
                                  class="mr-1 feather ft-edit"></i>Editar</button>
                            </div>
                          </div>
                          <div class="chat-item__avatar d-flex">
                            <div class="avatar">
                              <img src="../../../../assets/user.svg" alt="avtar img holder">
                            </div>
                          <div>
                            <span>{{chat['user']['title']}}</span>
                            <p class="truncate mb-0" style="font-size: 10px;">{{chat['fecha']}}</p>
                          </div>
                        </div>
                        </div>
                        <div class="media-body d-flex align-items-center" (click)="showEmail($event, chat,'2')" style="margin-top: -1rem;">
                          <div class="mr-1 ml-1" style="font-size: 13px; text-align: justify;" [innerHTML]="chat['mensaje']">
                          </div>
                          <div class="chat-item__file col-3 px-0" id="img-mobile">
                            <ngb-carousel [pauseOnHover]="true" interval="1500">
                              <ng-template ngbSlide *ngFor="let image of chat['url-image']">
                                <div>
                                  <img [src]="image" alt="img25" height="120" width="140">
                                </div>
                              </ng-template>
                            </ngb-carousel>
                          </div>
                          
                        </div>
                        </li>
                      </ul>
                      <!-- email user list end -->

                      <!-- no result when nothing to show on list -->
                      <div class="no-results">
                        <i class="ficon feather ft-info font-large-2"></i>
                        <h5>No Items Found</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Detalles de mensajes -->
                <div *ngIf="currentMessage != undefined">
                  <section class="email-app-details" id="app-details">
                    <div class="email-detail-header pb-1">
                      <span class="go-back mr-50">
                        <i class="ficon feather ft-chevron-left font-medium-4 align-middle"
                          (click)="showEmail($event, currentMessage,'3')"></i>
                      </span>
                    </div>
                    <div class="row pl-1 pr-1">
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="card shadow-none">
                          <div class="card-body">
                            <!-- Detalles -->
                            <div class="card shadow-none">
                              <div class="catd-body">
                                <div class="row p-0 pl-1">
                                  <div class="col-6">
                                    <div class="row">
                                      <div class="col-lg-4 col-3">
                                        <img src="../../../../assets/user.svg" alt=""
                                          class="img-fluid rounded-circle width-50">
                                      </div>
                                      <div class="col-lg-8 col-7 p-0">
                                        <h5 *ngIf="currentMessage['user'] != ''" class="m-0">
                                          {{currentMessage['user']['title']}}</h5>
                                        <p>{{currentMessage['fecha']}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="write-post">
                                  <div class="col-sm-12 px-2 pt-2 pb-4" [innerHTML]="currentMessage['mensaje']">
                                  </div>
                                  <hr class="m-0">
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <!-- Imagen -->
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="card shadow-none">
                          <div class="catd-body">
                            <div class="row p-1"></div>
                            <div class="row">
                              <div *ngFor="let image of currentMessage['url-image']">
                                <div class="col-sm-1 px-2 pb-2">
                                  <a [href]="image" target="_blank">
                                    <img target="_blank" [src]="image" alt="documento " height="100" width="160">
                                    <br>
                                  </a>

                                </div>

                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
        <div class="modal-header">
          <h4 class="modal-title">Algo salió mal, inténtalo nuevamente</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="icon-close-md" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-body icon-alert mt-3">
            <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
          </div>
          <h4 class="icon-alert mb-3"></h4>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="d('Close modal')">Aceptar</button>
        </div>
      </ng-template>

    </div>
  </div>

</div>

<app-calificacion-modal></app-calificacion-modal>

<!-- nuevo modal para editar -->
<ng-template class="modal text-left" #ModalEditar let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 class="negritaModal" id="myModalLabel23">Agregar Nuevas Imagenes</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeSoporteModal()">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>

    </div>
    <!-- Formulario -->
    <div class="mt-2">
      <form [formGroup]="soporteContact">
        <div class="form-body row px-2">
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Detalles</h3>
            </div>
            <div class="card">
              <article class="col-12 p-0">
                <div class="row">
                  <div class="col-12 p-0 pl-1">
                    <div class="list-group">
                      <div class="list-group-item  flex-column align-items-start">
                        <h4 class="text-black" style="color: black !important;">Folio</h4>
                        <p class="clr-txt">{{solicitudInfo.folio}}</p>
                        <h4 class="text-black">Cliente</h4>
                        <p class="clr-txt">{{conversacionSelect.user['title']}}</p>
                        <h4 class="text-black">Proyecto</h4>
                        <p class="clr-txt">{{solicitudInfo.proyecto}}</p>
                        <div class="form-group">
                          <label for="Descripcion">Brindanos una Descripción Breve</label>
                          <textarea id="Descripcion" rows="5" class="form-control" formControlName="description"
                            [ngClass]="{ 'is-invalid': submitted && fProject.description.errors }"
                            placeholder="Agrega una descripcion" required></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </article>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Imágenes</h3>
            </div>
            <ngx-dropzone class="_dropzone" [accept]="'image/*'" [disabled]="isDisabled"
              (change)="SelectMultipleFile($event,'editar')">
              <ngx-dropzone-label>
                <div class="dz-default dz-message">
                  <span class="dz-default">Arrastre las imágenes aquí <br> (Maximo {{limiteDeImagenes}})</span>
                </div>
              </ngx-dropzone-label>
            </ngx-dropzone>

            <div class="images__container1" style="
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            /* justify-content: center; */
            /* margin-left: 12px; */">
              <div *ngFor="let f of filesEdit" class="mr-1">
                <div class="images__item">
                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true"
                    (removed)="MultiplefilesonRemove(f,'editar')">
                  </ngx-dropzone-image-preview>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-actions pr-2 text-right">
          <button type="button" class="btn btn-danger mr-1" (click)="d('Close modal')">Cancelar</button>
          <button (click)="loadImages('editar')" class="btn clr-btn">
            <i class="la la-check"></i> Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- nuevo modal para enviar mensaje -->
<ng-template class="modal text-left" #ModalEnviarMensaje let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 class="negritaModal" id="myModalLabel23">Nuevo mensaje</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeSoporteModal()">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>

    </div>
    <!-- Formulario -->
    <div class="mt-2">
      <form id="compose-form" [formGroup]="newRevisionForm">
        <div class="form-body row px-2">
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Mensaje</h3>
            </div>
            <div class="card">
              <article class="col-12 p-0">
                <div class="form-group pb-50">
                  <input type="text" id="emailfrom" class="form-control" [value]="cliente" disabled>
                </div>
                <div class="form-label-group mb-1">
                  <quill-editor class="content-podio" id="txtContent" [modules]="modulesQuill"
                                formControlName="comment">
                                <div quill-editor-toolbar>
                                    <span class="ql-formats">
                                        <button type="button" class="ql-bold" title="Bold">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-stroke"
                                                    d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                </path>
                                                <path class="ql-stroke"
                                                    d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-italic">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line>
                                                <line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-underline">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-stroke"
                                                    d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path>
                                                <rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3"
                                                    y="15"></rect>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">

                                        <button type="button" class="ql-header" value="1">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-fill"
                                                    d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-header" value="2">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-fill"
                                                    d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z">
                                                </path>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">

                                        <button type="button" class="ql-list" value="ordered">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line>
                                                <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5">
                                                </line>
                                                <path class="ql-fill"
                                                    d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z">
                                                </path>
                                                <path class="ql-stroke ql-thin"
                                                    d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156">
                                                </path>
                                                <path class="ql-stroke ql-thin"
                                                    d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-list" value="bullet">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">
                                        <select class="ql-align" [title]="'Aligment'">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                                </div>
                            </quill-editor>
                </div>
                <div *ngIf="submitted && fNewComment.comment.errors " class="ml-2">
                  <div *ngIf="remember.comment.errors?.required">Este campo es requerido*</div>
                </div>
              </article>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Imágenes</h3>
            </div>
            <ngx-dropzone class="_dropzone" [accept]="'image/*'" [disabled]="isDisabled"
              (change)="SelectMultipleFile($event,'nuevo')">
              <ngx-dropzone-label>
                <div class="dz-default dz-message">
                  <span class="dz-default">Arrastre las imágenes aquí <br> (Maximo {{limiteDeImagenes}})</span>
                </div>
              </ngx-dropzone-label>
            </ngx-dropzone>
            <div class="images__container1" style="
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            /* justify-content: center; */
            /* margin-left: 12px; */">

              <div *ngFor="let f of files" class="mr-1">
                <div class="images__item">
                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true"
                    (removed)="MultiplefilesonRemove(f,'nuevo')">
                  </ngx-dropzone-image-preview>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-actions pr-2 text-right">
          <button type="button" class="btn btn-danger mr-2" (click)="d('Close modal')">Cancelar</button>
          <button (click)="loadImages('nuevo')" class="btn clr-btn">Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>



