import { ConectorApiService } from './conector-api.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SdServicesService {

  constructor(
    private _service: ConectorApiService
  ) { }

  promiseImage(image){
    return new Promise( (resolve, reject)=>{
      const filesForm = new FormData()
      filesForm.append('file', image)
        this._service.uploadImage(filesForm).subscribe( (res)=>{
          resolve(res['url'])
        })
    });
  }

  getCalificacionURL(calificacion){
    const calificaciones = [
    {
      calif: 'Muy Insatisfecho', 
      url: '../../../../../assets/images/encuesta/muy-insatisfecho-selected.svg'
    },
    {
      calif: 'Insatisfecho', 
      url: '../../../../../assets/images/encuesta/insatisfecho-selected.svg'
    },
    {
      calif: 'Neutral', 
      url: '../../../../../assets/images/encuesta/neutral-selected.svg'
    },
    {
      calif: 'Satisfecho', 
      url: '../../../../../assets/images/encuesta/satisfecho-selected.svg'
    },
    {
      calif: 'Muy Satisfecho', 
      url: '../../../../../assets/images/encuesta/muy-satisfecho-selected.svg'
    }
    ]
    return calificaciones[calificacion]
  }
}
