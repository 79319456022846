import { Router } from '@angular/router';
import { SdServicesService } from './../../../services/sd-services.service';
import { ConectorApiService } from './../../../services/conector-api.service';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DetailsModalComponent } from '../../utils/modals/details-modal/details-modal.component';
import { WelcomeModalComponent } from '../../utils/modals/welcome-modal/welcome-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-cliente-dashboard',
  templateUrl: './cliente-dashboard.component.html',
  styleUrls: ['./cliente-dashboard.component.css']
})
export class ClienteDashboardComponent implements AfterViewInit {
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;

  public contenido: any
  public contenidoTodo: any
  // public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : false, wheelPropagation: false };
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : true };
  @ViewChild(DetailsModalComponent) modalDetalle: DetailsModalComponent
  @ViewChild(WelcomeModalComponent) bienvenidoModal: WelcomeModalComponent

  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  //SOPORTE MODAL
  tiposList: any[] = []
  projectsList: any[] = []
  @ViewChild('SupportModal') soporteModal: NgbModal
  soporteContact: FormGroup;
  isDisabled: any
  files: File[] = [];  
  public selectedRow: any[]
  canSubmit:boolean
  imagesUrls: any = []
  isFinished: boolean;
  proyectoI: any;
  limiteDeImagenes=10;
  disabledCargar: boolean;
  disabledGuardar:boolean;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _service: ConectorApiService,
    private _sdService: SdServicesService,
    private _router: Router
  ) {
    this.contenido=[];
    this.contenidoTodo=[];
    let dataUserVerify = localStorage.getItem('perfil').toLowerCase()

    if (dataUserVerify != 'cliente') {
      _router.navigate(['/login']);
    }

    this.actualizarTabla();
    this.tiposList = [
      {id: 1, tipo:'Error de peticiones'},
      {id: 2, tipo:'Error de imagen'},
      {id: 3, tipo:'Bugs'},
      {id: 4, tipo:'Modificación'},
      {id: 5, tipo:'Mejora'},
      {id: 6, tipo:'Nuevos Requerimientos'}

    ]
  }

  ngAfterViewInit(): void {
    this.contenido=[];

    const logged = localStorage.getItem('logged')   
    if (logged == 'no') {
      localStorage.setItem('logged', 'si')
      this.bienvenidoModal.showModal() 
    }
    this.soporteContact = this.formBuilder.group({
      projects: new FormControl(this.projectsList,[Validators.required]),
      tipos: new FormControl([],[Validators.required]),
      description: new FormControl('',[Validators.required]),
      image: new FormControl([], [Validators.required])
    })
  }
  openSoporteModal() {
    this.proyectoI="abrir";
    this.modalService.open(this.soporteModal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }
  hideOption(){
    this.proyectoI=true;
    console.log("se llama")
  }
  closeSoporteModal() {
      this.modalService.dismissAll();
  }
getProyectos(){
  let proyectos = []
  if (this.contenido.length>0){
    this.contenido.forEach(element => {
      proyectos.push(element['proyecto'])
    });
    return proyectos
  }
  else{
    return ['']
  }
}
get fProject(){
    return this.soporteContact.controls
}
SelectMultipleFile(event) {
  console.log(event)
  this.files.push(...event.addedFiles);
    if(this.files.length>(this.limiteDeImagenes-1)){
      this.files = this.files.slice(0,this.limiteDeImagenes)
      this.isDisabled = true
    }
}

MultiplefilesonRemove(event) {
  this.files.splice(this.files.indexOf(event), 1);
  if(this.files.length<=this.limiteDeImagenes){
    this.isDisabled = false
    
  }
}
 onSubmitProject(){
  this.disabledGuardar=true;

    const projectData = {
      "proyecto": this.fProject.projects.value,
      "tipo": Number.parseInt(this.fProject.tipos.value),
      "descripcion": this.fProject.description.value,
      "image": this.imagesUrls
    }
    this._service.createNewSolicitude(projectData).subscribe( ()=>{
      //this.isFinished==false
      this.closeSoporteModal();
      this._service.getSolicitudes().subscribe( (response:any)=>{
        if(response.ok){
          this.contenido = response.data
          this.limpiarCajas();  

        }else{
          this.disabledGuardar=false;
          this._service.mensajeError(response.msg);  

        }

      })
    })
  }
  async loadImages(){
    this.disabledCargar=true;
    if(this.files.length>0){
      const array = this.files
      await this.promiseLoop(array)
    }

    console.log(this.imagesUrls)
    await this.onSubmitProject()
  }
  
  async promiseLoop(array){
    
    for(let i = 0; i < array.length; i++){
      const form = new FormData();
      form.append("file",array[i])
      const response: any = await this._service.uploadImage(form).toPromise();
      this.imagesUrls.push(response['url'])
    }
  }
  finishLoop(){
    this.isFinished = true
    this.disabledCargar=false;
    this.limiteDeImagenes=this.limiteDeImagenes-this.imagesUrls.length;
    this.files=[];
  }   


  detalles(row){
      this.selectedRow = row
      this.modalDetalle.showModal()
  }

  archivar(row){
    this._service.solicitudesActions(row, 'Archivar').subscribe ((res:any)=>{
      console.log('solicitud archivada',res)
      if(res.msg=="Proceso Correcto"){
        this.actualizarTabla();
      }

    })
  }

  search(e) {
    if (e.target.value == "") {
      return (this.contenido = this.contenidoTodo);
    }
    const result = this.contenidoTodo.filter((x) =>
      x?.folio?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.consultor?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.proyecto?.toString()?.toLowerCase().includes(e.target.value.toString()?.toLowerCase()) || 
      x?.tipo?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.fechaconsulta?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) || 
      x?.estatus?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) 
    );
    this.contenido = result;
  }

  borrarAceptar(){
    console.log('eliminar')
    this._service.solicitudesActions(this.selectedRow, 'Eliminar').subscribe ( (res:any)=>{
      console.log('la solicutd ha sido eliminada')
      if(res.ok){
        this.actualizarTabla();
      }
      this.modalService.dismissAll()
    })
  }

  eliminar(EliminarFormContent, row) {
    this.selectedRow = row
    this.modalService.open(EliminarFormContent, { windowClass: 'animated fadeInDown' });
  }

  actualizarTabla(){
    this._service.getSolicitudes().subscribe( (response:any)=>{
      if(response.ok){
        let fechaConsulta = response.data.map( d=>{return { ...d, fechaconsulta: d['fecha-consulta']}});
        this.contenido = fechaConsulta
        this.contenidoTodo = this.contenido
        this.projectsList=JSON.parse(localStorage.getItem('proyectos'));
      }else{
        this._service.mensajeError(response.msg);
        this.contenido = undefined
        this.contenidoTodo = this.contenido
      }
    })

  }

  limpiarCajas(){

    this.fProject.projects.setValue(null);
    this.fProject.tipos.setValue(null);
    this.fProject.description.setValue(null);
    this.imagesUrls=[]
    this.isFinished = false
    this.isDisabled = false
    this.disabledGuardar=false;
    this.limiteDeImagenes=10;
    this.files=[]
  }

}
