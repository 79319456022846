import { Router } from '@angular/router';
import { CrearSoporteModalComponent } from './../../utils/modals/crear-soporte-modal/crear-soporte-modal.component';
import { AdminModalsComponent } from './../../utils/modals/admin-modals/admin-modals.component';
import { ConectorApiService } from './../../../services/conector-api.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-catalogo-consultores',
  templateUrl: './catalogo-consultores.component.html',
  styleUrls: ['./catalogo-consultores.component.css']
})
export class CatalogoConsultoresComponent implements OnInit {
  public selectedRow: any
  public contenido: any
  public contenidoTodo:any;
  @ViewChild(AdminModalsComponent) modals: AdminModalsComponent
  @ViewChild(CrearSoporteModalComponent) crearModal: CrearSoporteModalComponent
  public modalLabels: any
  constructor(
    private _service: ConectorApiService,
    private _router: Router
  ) {
    this.contenido=[];
    this.contenidoTodo=[];

    let dataUserVerify = localStorage.getItem('perfil').toLowerCase()
    if (dataUserVerify != 'administrador') {
      this._router.navigate(['/login']);
    }

    this._service.getConsultores().subscribe( (res:any)=>{
      if(res.ok){
        this.contenido = res.data.map( ele =>{ ele.folio = "SD-C"+ele.folio; return ele})
        this.contenidoTodo = this.contenido
        console.log('res',res)

      }else{
        this._service.mensajeError(res.msg);

        this.contenido = undefined
        this.contenidoTodo = this.contenido
      }

    })
  }

  ngOnInit(): void {
    
  }
  search(e) {
    if (e.target.value == "") {
      return (this.contenido = this.contenidoTodo);
    }
    const result = this.contenidoTodo.filter((x) =>
      x?.folio?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.nombre?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.email?.toString()?.toLowerCase().includes(e.target.value.toString()?.toLowerCase())
    );
    this.contenido = result;
  }
  openCreate(){
    this.modals.option = 'consultor';
    this.crearModal.show()
  }
  borrar(row){
    this.modals.option = 'consultor';
    this.selectedRow = row
    this.modals.showDelete()
  }
  editar(row){
    this.modals.option = 'consultor';
    this.selectedRow = row
    console.log('editar',row)
    const labels = {
      title: 'Editar Consultor',
      inputNombre: 'Nombre',
      inputCorreo: 'Correo',
      inputContrasena: 'Contraseña',
      inputEstatus: 'Estatus',
      inputNotificacion: 'Notificación',
      nombre: row['nombre'],
      correo: row['email'],
      contrasena: row['pasword2'],
      estatus:row['estatus'],
      notificacion: row['notificacion']
    }
    console.log(labels);
    this.modalLabels = labels
    this.modals.showEdit()
  }
  
/*   ngOnInit() {
    let dataUserVerify = this.getDataUser();
    if (dataUserVerify != undefined) {
      this._router.navigate(['/tabs']);
    }
  } */
}
