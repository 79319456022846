<div class="app-content content mt-1">
      <div class="content-body">
        <!-- barra de busqueda -->
        <article class="col-12 pb-2">
          <fieldset class="form-group position-relative has-icon-left m-0">
            <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
            placeholder="Buscar por Folio, Cliente, Proyecto, Tipo y Fecha aquí ...">
            <div class="form-control-position">
              <i class="feather ft-search"></i>
            </div>
          </fieldset>
        </article>
        <!-- Tabla de solicitudes  -->
        <div class="row match_height">
          <section id="recent-transactions" class="col-12">
            <article class="card ml-1 mr-1">
              <div class="mt-2 d-flex justify-content-between">
                <h4 class="ml-1">Seguimiento a soporte</h4>
                <div class="heading-elements invoice">
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-md clr round btn-min-width pull-right dropdown-toggle mr-1"  id="dropdownBasic1"
                    ngbDropdownToggle>Filtrar Estatus</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button (click)="filtrar('Todos')" class="dropdown-item">Todos</button>
                    <button (click)="filtrar('Revision')"class="dropdown-item">Revisión</button>
                    <button (click)="filtrar('Finalizada')"class="dropdown-item">Finalizada</button>
                    </div>
                  </div>
                </div>
    
              </div>
                <div class="card-dashboard col-12 mt-1 mb-1 " *ngIf="contenido!=undefined">
                  <!-- [perfectScrollbar]="configScroll" -->
                  <ngx-datatable class="bootstrap table-bordered " [limit]="20" [rows]="contenido" [columnMode]="'flex'"
                    [headerHeight]="'auto'" [footerHeight]="40" [rowHeight]="'auto'" fxFlex="auto">
                    <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Folio" prop="folio"  [maxWidth]="60" [flexGrow]="1">
                      <ng-template ngx-datatable-cell-template let-row="row"   let-value="value">
                          <div  class="text-center">{{value}}</div> 
                      </ng-template>
                    </ngx-datatable-column>

                      <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Cliente" prop="cliente.title" [minWidth]="190" [flexGrow]="2">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div  class="text-center">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Proyecto" prop="proyecto" [minWidth]="100" [flexGrow]="1.3">
                        <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                            <div  class="text-center">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo" [minWidth]="110" [flexGrow]="1.3">
                        <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                            <div  class="text-center">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column headerClass='text-left' [resizeable]="false" [draggable]="false" name="Descripción" prop="descripcion" [minWidth]="370" [flexGrow]="4.5">
                        <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                            <div  class="text-left" [innerHTML]="value">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Fecha Consulta" prop="fecha-consulta" [minWidth]="120" [flexGrow]="1.5">
                        <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                            <div  class="text-center">{{value | date:'dd-MM-YYYY' }}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus" [minWidth]="90" [flexGrow]="1">
                        <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                            <div  class="text-center">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column  headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [minWidth]="120" [flexGrow]="1.2">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <div class="w-100 center-items">
                              <i class="font-medium-2 feather ft-edit-2 info mr-1 pl-1 cursor" title="Editar" (click)="editar(row)" *ngIf="row.estatus!='Finalizada'"></i>
                              <i class="font-medium-3 la la-trash danger cursor" title="Eliminar" (click)="borrar(row)"></i>
                            </div>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
            </article>
          </section>
        </div>
      </div>
</div>

<admin-modals [info]="selectedRow"></admin-modals>
