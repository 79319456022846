import { Component, OnInit, Renderer2, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-sd-layout',
  templateUrl: './sd-layout.component.html',
  styleUrls: ['./sd-layout.component.css']
})
export class SdLayoutComponent implements OnInit {


  isSuccessClosed9= true;
  isDangerClosed9 = true;

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.renderer.removeClass(document.body, 'blank-page');
  }


}



