import { ConectorApiService } from './../../../../services/conector-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit,  EventEmitter,ViewChild, ɵɵtrustConstantResourceUrl,Output } from '@angular/core';
import { NgSelectDataService, City } from '../../../../_services/ng-select-data.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';

declare var require: any;
const selectData = require('../../../../../assets/data/forms/form-elements/select.json');

@Component({
  selector: 'asignar-modal',
  templateUrl: './asignar-modal.component.html',
  styleUrls: ['./asignar-modal.component.css']
})
export class AsignarModalComponent implements OnInit {
  @ViewChild('ng-select') ngSelect;
  @BlockUI('multipleSelect') blockUIMultipleSelect: NgBlockUI;
  @Output() actualizar: EventEmitter<any> = new EventEmitter();

  @Input() info: any
  @Input() labels: any
  @Input() option: string
  @ViewChild('AsignarModal') modal: NgbModal
  @Input() headers: string[]
  public selectOptions: any[]
  public optionValue: string
  multipleMultiSelect: any;
  matchedResultArray: City[] = [];
  cityLoading = false;
  public multipleSelectArray = selectData.multipleSelectArray;
  constructor(
    private modalService: NgbModal,
    public _service: ConectorApiService,
    private dataService: NgSelectDataService
  ) { }
  

  ngOnInit(): void {
    this.multipleMultiSelect = [{ item_id: 1, item_text: 'Alaska' }, { item_id: 2, item_text: 'California' }];

    this.info = this.info == undefined
      ?{}
      :this.info
  }
  show(data){
    const load = {
      proyecto: 'getProyectosEmpresa',
      consultor: 'getConsultores'
    }

    if (this.option == 'proyecto') {
      console.info(this.selectOptions);
      this.selectOptions = data.filter( d => d["nombre-empresa"] == this.info["empresa"])
      if(this.info['proyectos']!=""){

        JSON.parse(this.info['proyecto']).forEach(element => {

          let indice=this.selectOptions.findIndex(el=>el.proyecto==element.proyecto);

            if(indice!=-1){
              this.selectOptions[indice].checked=true
            }

        });

        this.selectOptions.forEach(element => {
          if(element.checked==undefined || element.checked==null){
            element.checked=false;
          }
        });

      }else{
        this.selectOptions.forEach(element => {
          element.checked=true;
        });
      }

      
      this.modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }else{
      this.selectOptions = data
      this.modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }

  }

  loadCity() {
    this.cityLoading = true;
    this.dataService.getPeople().subscribe(x => {
      this.matchedResultArray = x;
      this.cityLoading = false;
    });
  }

  asignarSubmit(e){
    e.preventDefault()
    if (this.option == 'proyecto') {
      let proyecto="["
      this.selectOptions.forEach(element => {

        if(element.checked){
          if(proyecto!="["){
            proyecto=proyecto+",";
          }
          proyecto=proyecto+'{"proyecto":"'+element.proyecto+'"}'
        }
      });

      proyecto=proyecto+"]";

      let check=false;

      this.selectOptions.forEach(element => {
        if(element.checked){
          check=true;
        }
      });
      if(!check){
        Swal.fire({
          icon: 'error',
          text: 'Es necesario seleccionar proyectos para asignar.'
        })
        return
      }

      const data = {
        id: this.info['item_id'],
        'proyecto': proyecto
      }  

      this._service.asignarProyecto(data).subscribe(res=>{
        this.selectOptions = [];
        this.actualizar.emit();
        this.modalService.dismissAll()
        
      })
    } else {
      const data = {
        id: this.info['item_id'],
        'consultor': e.target[0].value
      } 
      this._service.asignarConsultor(data).subscribe(res=>{

        this.actualizar.emit();
        this.modalService.dismissAll()
        location.reload()
      }) 
    }
  }

  cambiarEstatus(proyecto,ev){

    this.selectOptions.forEach(element => {
      if(element.proyecto==proyecto){
        if (ev.currentTarget.checked) {
          element.checked=true;
        }else{
          element.checked=false;
        }

      }
    });

  }
}


