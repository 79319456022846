<div class="app-content content">
    <div class="content-wrapper">
         <!-- navbar -->
         <nav  class="header-navbar navbar-expand-md navbar navbar-without-dd-arrow fixed-top navbar-clr navbar-shadow">
            <!-- <div class="navbar-wrapper">
                <div class="navbar-header">
                </div>
                <div class="navbar-container">
                </div>
            </div> -->
        </nav>
        <!-- <div class="content-header row mb-1">
        </div> -->
        <div class="content-body mt-3 py-1">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0 pb-0">
                                <div class="card-title text-center">
                                    <img src="assets/images/logo/logo-sv.png" alt="branding logo" width="130"> 
                                    <!-- <strong>S</strong>istema de <strong>A</strong> -->
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                    <span>Crear Cuenta</span></h6>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="registerForm" (ngSubmit)="tryRegister()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="firstName" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                                                placeholder="Nombre Completo" autocomplete="off" spellcheck="false">
                                            <div class="form-control-position">
                                                <i class="feather ft-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                <div *ngIf="f.firstName.errors.required">Nombre completo requerido*</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input  type="email" formControlName="email" class="form-control" email
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                placeholder="Correo Electrónico"  autocomplete="off" spellcheck="false" required>
                                            <div class="form-control-position">
                                                <i class="feather ft-mail"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Correo electrónico requerido*</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left" autocomplete="off">
                                            <input type="password" formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                id="user-password" placeholder="Contraseña" autocomplete="off" required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Contraseña requerida*</div>
                                                <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6
                                                    characters</div> -->
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <select id="empresa" class="form-control" formControlName="empresa"
                                                [ngClass]="{ 'is-invalid': submitted && f.empresa.errors }"required>
                                                <option selected hidden value="">Empresa</option>
                                                <option *ngFor="let empresa of empresas" value="{{empresa}}">{{empresa}}</option>
                                            </select>
                                            <div class="form-control-position">
                                                <i class="la la-building"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">La empresa es requerida*</div>
                                            </div>
                                        </fieldset>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-user" *ngIf="!submitted"></i> Registrarse
                                        </button>
                                    </form>
                                </div>
                                <p class="card-subtitle text-muted text-center font-small-4 mx-2 my-1">
                                    <span>¿Ya tienes una cuenta?</span> <a [routerLink]="['/login']" class="card-link"> Iniciar Sesión</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>