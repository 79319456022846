<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
        <!-- barra de busqueda -->
        <article class="col-12 pb-2">
            <fieldset class="form-group position-relative has-icon-left m-0">
              <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
              placeholder="Buscar por Nombre, Correo, Proyecto y Consultor aquí ...">
              <div class="form-control-position">
                <i class="feather ft-search"></i>
              </div>
            </fieldset>
          </article>
        <!-- Tabla de solicitudes  -->
        <div class="row match_height">
          <section id="recent-transactions" class="col-12">
            <article class="card ml-1 mr-1">
              <div class="card-header">
                <h4 class="ml-1">Seguimiento a Clientes</h4>
                <div class="heading-elements invoice">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-md clr round btn-min-width pull-right dropdown-toggle mr-1"  id="dropdownBasic1"
                        ngbDropdownToggle>Filtrar Estatus</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button (click)="filtrar('Todos')"class="dropdown-item">Todos</button>
                        <button (click)="filtrar(true)"class="dropdown-item">Activos</button>
                        <button (click)="filtrar(false)"class="dropdown-item">Inactivos</button>
                        </div>
                    </div>
                </div>

              </div>
              <section class="card-body">
                <div class="card-dashboard col-12 table-responsive-md">
                    <ngx-datatable class="bootstrap table-bordered" [limit]="30" [rows]="contenido" [columnMode]="'flex'"
                      [headerHeight]="'auto'" [footerHeight]="40" [rowHeight]="'auto'" fxFlex="auto">
  
                      <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Nombre" cellClass='p-0' headerClass='text-center' prop="nombre" [width]="'auto'" [flexGrow]="1">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div class="w-100 rowHeight center-items">{{value}}</div> 
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column cellClass='p-0' headerClass='text-center' [resizeable]="false" [draggable]="false" name="Correo Electrónico" prop="email" [width]="'auto'" [flexGrow]="1.3">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div class="w-100 rowHeight center-items">{{value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column cellClass='p-0' headerClass='text-center' [resizeable]="false" [draggable]="false" name="Proyecto" prop="proyectos" [width]="'auto'" [flexGrow]="1.5">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                          <div (click)="asignarProject(row)" class="w-100 center-items cursor">{{value==''?'No Asignado':value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column cellClass='p-0' headerClass='text-center' [resizeable]="false" [draggable]="false" name="Consultor" prop="consultor" [width]="'auto'" [flexGrow]="1">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <div (click)="asignarConsultor(row)" class="w-100 rowHeight center-items cursor">{{value==''?'No Asignado':value}}</div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column cellClass='p-0' headerClass='text-center' [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus" [width]="'auto'" [flexGrow]="0.5">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <div class="w-100 rowHeight center-items">{{value==''?'Inactivo':value}}</div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="1">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <div class="w-100 center-items">
                              <ui-switch [checked]="row.isActive" (change)="onActiveChange(row)" size="small"></ui-switch>
                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                  </div>
              </section>
            </article>
          </section>
        </div>
      </div>
    </div>
</div>

<asignar-modal [info]="selectedRow" (actualizar)="actualizar()"></asignar-modal>