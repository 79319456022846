<ng-template class="modal text-left" #AsignarModal let-c="close" let-d="dismiss">
   <div class="modal-lg">
     <div class="modal-header">
       <h4 id="myModalLabel23">{{labels.title}}</h4>
       <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
         <span class="icon-close-md" aria-hidden="true">&times;</span>
       </button>
     </div>
     <!-- titulos del modal en info -->
       <div class="p-2">
         <!-- Tabla de datos -->
         <article class="mb-2">
           <table class="table"> 
             <thead>
               <tr >
                 <th class="text-white bg-info" *ngFor="let header of headers">{{header | titlecase}}</th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td >{{info.nombre | titlecase}}</td>
                 <td >{{info.email | titlecase}}</td>
                 <td *ngIf="option=='consultor'" >{{info.proyectos | titlecase}}</td>
               </tr>
             </tbody>
           </table>
         </article>
         <!-- SELECT -->
          <article class="row">
            <form class="px-2 pb-2 mrg col-12" (submit)="asignarSubmit($event)">
            <div class="col-6" matchHeight="card">
                <div *ngIf="option=='proyecto'">
                  <label for="Descripcion">{{labels.input}}</label>
                 <!-- <select [ngModel]="optionValue" class="form-control" required>
                    <option hidden selected value="">{{info.proyecto==''?'No Asignado':info.proyecto }}</option>
                    <option [value]="proyecto['proyecto']" *ngFor="let proyecto of selectOptions">{{proyecto['proyecto']}}</option>
                  </select>-->
                </div>
                
                <div *ngIf="option=='consultor'">
                  <label for="Descripcion">{{labels.input}}</label>
                  <select [ngModel]="optionValue" class="form-control" required>
                    <option hidden selected value="" >{{info.consultor==''?'No Asignado':info.consultor }}</option>
                    <option [value]="consultor['nombre']" *ngFor="let consultor of selectOptions">{{consultor['nombre']}}</option>
                  </select>
                </div>
              </div>

              <div *ngIf="option=='proyecto'" class="row">
                <div *ngFor="let proyecto of selectOptions" class="col-3">

                  <input type="checkbox" [checked]="proyecto['checked']" (change)="cambiarEstatus(proyecto['proyecto'],$event)">{{proyecto['proyecto']}}

                </div>
              </div>

              <div class="text-right mt-1 px-2">
                <button class="btn clr">
                  Asignar
                </button>
              </div>
              </form>
          </article>

          <!-- propuesta nuevo select multiple  -->

        </div>
   </div>
 </ng-template>