// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    soporte: {
      items: Partial<MenuItem>[]
    },
    cliente: {
      items: Partial<MenuItem>[]
    },
    administrador:{
      items: Partial<MenuItem>[]
    }
  };
  vertical_menu: {
    soporte: {
      items: Partial<MenuItem>[]
    },
    cliente: {
      items: Partial<MenuItem>[]
    },
    administrador:{
      items: Partial<MenuItem>[]
    }
  };
  ordinal_menu: {
    soporte: {
      items: Partial<MenuItem>[]
    },
    cliente: {
      items: Partial<MenuItem>[]
    },
    administrador:{
      items: Partial<MenuItem>[]
    }
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    soporte: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'feather ft-inbox',
          page: 'soporte',
        }
      ]
    },
    cliente: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'feather ft-inbox',
          page: 'cliente',
        },
        {
          title: 'Solicitudes Archivadas',
          icon: 'feather ft-folder',
          page: 'cliente-archivo',
        }
      ]
    },
    administrador: {
      items: [
        {
          title: 'Solicitudes',
          icon: 'la-file-text',
          page: 'solicitudes-admin',
        },
        {
          title: 'Clientes',
          icon: 'feather ft-file-text',
          page: 'usuarios-admin',
        },
        {
          title: 'Catálogo de Proyectos',
          icon: 'feather ft-file-text',
          page: 'cat-proyectos',
        },
        {
          title: 'Catálogo de Consultores',
          icon: 'feather ft-file-text',
          page: 'cat-consultores',
        }
      ]
    },
  },
  vertical_menu: {
    soporte: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'feather ft-inbox',
          page: 'soporte',
        }
      ]
    },
    cliente: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'feather ft-inbox',
          page: 'cliente',
        },
        {
          title: 'Solicitudes Archivadas',
          icon: 'feather ft-folder',
          page: 'cliente-archivo',
        }
      ]
    },
    administrador: {
      items: [
        {
          title: 'Solicitudes',
          icon: 'la-file-text',
          page: 'solicitudes-admin',
        },
        {
          title: 'Clientes',
          icon: 'feather ft-file-text',
          page: 'usuarios-admin',
        },
        {
          title: 'Catálogo de Proyectos',
          icon: 'feather ft-file-text',
          page: 'cat-proyectos',
        },
        {
          title: 'Catálogo de Consultores',
          icon: 'feather ft-file-text',
          page: 'cat-consultores',
        }
      ]
    },
  },
  ordinal_menu: {
    soporte: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'feather ft-inbox',
          page: 'soporte',
        }
      ]
    },
    cliente: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'feather ft-inbox',
          page: 'cliente',
        },
        {
          title: 'Solicitudes Archivadas',
          icon: 'feather ft-folder',
          page: 'cliente-archivo',
        }
      ]
    },
    administrador: {
      items: [
        {
          title: 'Solicitudes',
          icon: 'la-file-text',
          page: 'solicitudes-admin',
        },
        {
          title: 'Clientes',
          icon: 'feather ft-file-text',
          page: 'usuarios-admin',
        },
        {
          title: 'Catálogo de Proyectos',
          icon: 'feather ft-file-text',
          page: 'cat-proyectos',
        },
        {
          title: 'Catálogo de Consultores',
          icon: 'feather ft-file-text',
          page: 'cat-consultores',
        }
      ]
    },
  }

};





