import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'soporteform-modal',
  templateUrl: './soporteform-modal.component.html',
  styleUrls: ['./soporteform-modal.component.css']
})
export class SoporteformModalComponent implements OnInit {

  tiposList: any[] = []
  projectsList: any[] = []
  @ViewChild('SupportModal') modal: NgbModal
  soporteContact: FormGroup;

  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  files: File[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private _modalService: NgbModal
    ) { }

  ngOnInit(): void {
    this.soporteContact = this.formBuilder.group({
      projects: new FormControl(this.projectsList,[Validators.required]),
      tipos: new FormControl(this.tiposList,[]),
      description: new FormControl('',[])
    })
  }
  showModal() {
    this._modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }
  get fProject(){
    return this.soporteContact.controls
  }
  SelectMultipleFile(event) {
    this.files.push(...event.addedFiles);
}
onSelect(event) {
  console.log(event);
  this.files.push(...event.addedFiles);
}
  onSubmitProject(){
    console.log(this.soporteContact)
    console.log(this.fProject)
  }

}
