import { ConectorApiService } from './../../../../services/conector-api.service';
import { Component, Input, OnInit, Output, EventEmitter,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-eliminar-imagenes',
  templateUrl: './eliminar-imagenes.component.html',
  styleUrls: ['./eliminar-imagenes.component.css']
})
export class EliminarImagenesComponent implements OnInit {

  @ViewChild('ModalEliminar') modal: NgbModal
  @Input() eliminar
  @Output() actualizar: EventEmitter<any> = new EventEmitter()
  selectedUrl: string
  calificacionURL
  disabledEliminar:boolean;
  infoSolicitud=[];
  imagenes=[]
  constructor(
    private _modalService: NgbModal,
    private _router: Router,
    private _service: ConectorApiService,
    private redirect: ActivatedRoute
  ) {
    this.eliminar={}
    
   }

  ngOnInit(): void {

  }

ngOnChanges(){
  this.imagenes=[];

  this.infoSolicitud=JSON.parse(localStorage.getItem('solicitud'));
  if(this.infoSolicitud['url-image']!=undefined){
    this.infoSolicitud['url-image'].forEach(element => {
    
      this.imagenes.push({
        "checked":false,
        "url":element
      });
  
    });

  }

}

  showModal() {
    this._modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }

  close(){
    this._modalService.dismissAll();

  }

  cambiarChecked(url){

    let indice=this.imagenes.findIndex(el=>el.url==url);
    this.imagenes[indice].checked=!this.imagenes[indice].checked;

  }

  eliminarImagenes(){
    let totalChecks=0;
    this.imagenes.forEach(element => {
      if(element.checked){
        totalChecks++;
      }
    });

    if(totalChecks==0){
      
      return Swal.fire({
        icon: 'error',
        text: "No hay imagenes seleccionadas."
      })

    }
    this.disabledEliminar=true;

    let data={"idSolicitud":this.infoSolicitud['item_id'],
              "folio":this.infoSolicitud['folio'],
              "listaImagen":this.imagenes};

              this._service.deleteImages(data).subscribe( (res:any)=>{

                if(res.ok){
                  this.imagenes=res.data
                  this.actualizar.emit(this.imagenes);
                  this.disabledEliminar=false;
                  this.close();
                }else{
                  this.disabledEliminar=false;

                }

              })

  }

}
