import { Router } from '@angular/router';

import { ConectorApiService } from './../../../services/conector-api.service';
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import {PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ValidatorFn, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { CalificacionModalComponent } from '../../utils/modals/calificacion-modal/calificacion-modal.component';
import { AgregarImagenesComponent } from '../../utils/modals/agregar-imagenes/agregar-imagenes.component';
import { EliminarImagenesComponent } from '../../utils/modals/eliminar-imagenes/eliminar-imagenes.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { SdServicesService } from './../../../services/sd-services.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-chat-cliente',
  templateUrl: './chat-cliente.component.html',
  styleUrls: ['./chat-cliente.component.css']
})
export class ChatClienteComponent implements OnInit {
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };

  @ViewChild(CalificacionModalComponent) calificacion: CalificacionModalComponent
  //@ViewChild(AgregarImagenesComponent) agregarImagenes: AgregarImagenesComponent
  @ViewChild(EliminarImagenesComponent) eliminarImagenes: EliminarImagenesComponent

  @ViewChild('ModalAgregar') agregarImagenes: NgbModal

  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  user2: any = true;
  cliente: any
  consultor: any
  newRevisionForm: FormGroup;
  submitted: any
  solicitudInfo=JSON.parse(localStorage.getItem('solicitud'));
  imagesList=[]
  imagesUrl: any
  conversacion: any
  currentMessage: any
  firstMessage: any
  formFile: any
  files: File[] = [];
  filesEdit: File[] = [];  
  limiteDeImagenes=10;
  conversacionSelect: any; 
  isDisabled: any;
  imagesUrls: any = [];
  imagesUrlsEdit: any = [];
  imagesUrlsExistente=[]
  isFinished: boolean;
  soporteContact: FormGroup;
  disabledCargar: boolean;
  disabledGuardar:boolean;
  tipo: any;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private _service: ConectorApiService,
    private _router: Router,
  )
  {
    this.soporteContact = this.formBuilder.group({
      image: new FormControl([], [Validators.required]),
      description: new FormControl("",[Validators.required])
    })
    this.actualizarConversacion();
  }

  ngOnInit(): void {

    this.newRevisionForm = this.formBuilder.group({
      comment: new FormControl('',[Validators.required]),
      file: new FormControl('',[this.validatorExt(['pdf','doc','docx'])])
    })
  }

    loadData(){
      this.imagesUrlsExistente = this.solicitudInfo["url-image"]
      this.cliente = this.solicitudInfo['cliente']['title']
      this.firstMessage= {
        user: this.solicitudInfo['cliente'],
        fecha:  this.solicitudInfo['fecha-consulta'],
        mensaje: this.solicitudInfo['descripcion'],
        "url-image": this.solicitudInfo["url-image"]
      }
    }

    get fNewComment() {
      return this.newRevisionForm.controls
    }

    get fEditComment() {
      return this.soporteContact.controls
    }

    actualizarConversacion(){
      const clientId =  {"item_id": this.solicitudInfo['item_id']}
      this._service.getConversacion(clientId).subscribe( (res: any)=>{
        this.loadData();
        this.conversacion = res.reverse();
      },
      err =>{
        this.loadData();
      })
    }
    
    onFileChange(event) {
      if (event.target.files.length > 0) {
        this.formFile = event.target.files[0];
      }
    }

    SelectMultipleFile(event,tipo) {

      if(tipo=="nuevo"){
      this.files.push(...event.addedFiles);
        if(this.files.length>(this.limiteDeImagenes-1)){
          this.files = this.files.slice(0,this.limiteDeImagenes)
          this.isDisabled = true
        }
      }else if(tipo=="editar"){
        this.filesEdit.push(...event.addedFiles);
        if(this.filesEdit.length>(this.limiteDeImagenes-1)){
          this.filesEdit = this.filesEdit.slice(0,this.limiteDeImagenes)
          this.isDisabled = true
        }
      }
    }

    MultiplefilesonRemove(event,tipo) {
      if(tipo == "nuevo"){
        this.files.splice(this.files.indexOf(event), 1);
        if(this.files.length<=this.limiteDeImagenes){
          this.isDisabled = false
        }
      }else if(tipo == "editar"){
        this.filesEdit.splice(this.filesEdit.indexOf(event), 1);
        if(this.filesEdit.length<=this.limiteDeImagenes){
          this.isDisabled = false
        }
      }
    }
    
    async loadImages(tipo){
      if(tipo =="nuevo"){
        if(this.files.length>0){
          const array = this.files
          await this.promiseLoop(array,tipo);
        }
        await this.onSubmit()
      }else if(tipo == "editar"){
        if(this.filesEdit.length>0){
          const array = this.filesEdit
          await this.promiseLoop(array,tipo);
        }
        if(this.tipo == "solicitud"){
          await this.onSubmitProjectFirstMessage()
        }else{
          await this.onSubmitProject()
        }
      }
    }

    async promiseLoop(array,tipo){
      for(var i = 0; i < array.length; i++){
        
        const form = new FormData();
        form.append("file",array[i])
        
        if(tipo == "nuevo"){
          
          const response: any = await this._service.uploadImage(form).toPromise();
          this.imagesUrls.push(response['url'])
        
        } else if ("editar") {
          
          const file = this.conversacionSelect["url-image"]?.find( ele =>
            ele.replace('https://archivos.dsindigo.com/service_desk/',"") == array[i].name
          )
          
          if(!file){
            console.log("no encontro")
            const response: any = await this._service.uploadImage(form).toPromise();
            this.imagesUrlsEdit.push(response["url"])
          }else{
            console.log("si encontro")
            this.imagesUrlsEdit.push('https://archivos.dsindigo.com/service_desk/'+array[i].name)
          }
        }
      }
      console.log(this.imagesUrlsEdit)
    }

    onSubmit(){
      
      this.submitted = true
      const newMessageData = {
        'item_id': this.solicitudInfo['item_id'],
        'mensaje': this.fNewComment.comment.value,
        'url': this.imagesUrls
      }
      this._service.createNewMessage(newMessageData).subscribe( (res:any)=>{
        this.submitted = false
        if(res.ok){
          this.fNewComment.comment.setValue("");
          this.files = []
          this.actualizarConversacion();
          this.closeSoporteModal();
        }
      })

    }

    onSubmitProjectFirstMessage(){
      const request = {
        descripcion: this.fEditComment.description.value,
        idSolicitud: this.solicitudInfo["item_id"],
        image: this.imagesUrlsEdit
      }

      this._service.actualizarImagenSolicitud(request).subscribe( (res:any)=>{
        if(res.ok){
          this.isFinished = false
          this.isDisabled = false
          this.solicitudInfo['descripcion'] = request.descripcion
          this.solicitudInfo["url-image"] = this.imagesUrlsEdit
          this.firstMessage= {
            user: this.solicitudInfo['cliente'],
            fecha:  this.solicitudInfo['fecha-consulta'],
            mensaje: this.solicitudInfo['descripcion'],
            "url-image": this.solicitudInfo["url-image"]
          }
          localStorage.setItem("solicitud",JSON.stringify(this.solicitudInfo));
          this.tipo = null;
          this.filesEdit=[]
          this.imagesUrlsEdit=[]
          this.closeSoporteModal();
        }else{
          this.disabledGuardar=false;
          Swal.fire({
            icon: 'error',
            text: res.msg
          })
        }
      })
    }

    onSubmitProject(){
      const request = {
        mensaje: this.fEditComment.description.value,
        solicitud: {
          item_id: this.solicitudInfo["item_id"]
        },
        item_id: this.conversacionSelect.item_id,
        url: this.imagesUrlsEdit
      }

      this._service.editNewMessage(request).subscribe( (res:any)=>{
        if(res.ok){
          this.imagesUrlsEdit=[]
          this.isFinished = false
          this.isDisabled = false
          this.filesEdit=[]
          this.actualizarConversacion();
          this.closeSoporteModal();
        }else{
          this.disabledGuardar=false;
          Swal.fire({
            icon: 'error',
            text: res.msg
          })
        }
      })
    }

    reloadCurrentRoute() {
      let currentUrl = this._router.url;

      this._router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
          this._router.navigate([currentUrl]);
      });
    }

    enviarMensajeModal(ModalEnviarMensaje) {
      this.modalService.open(ModalEnviarMensaje, { windowClass: 'animated fadeInDown', size:'lg' });
    }

    calificar(){
      this.calificacion.info = this.solicitudInfo
      this.calificacion.showModal()
    }
    eliminarImg(){
      this.solicitudInfo=JSON.parse(localStorage.getItem('solicitud'));
      this.eliminarImagenes.showModal()
    }
    agregarImg(chat,tipo){
      if(tipo=="solicitud"){
        this.tipo = tipo;
      }

      this.limiteDeImagenes=10-(chat["url-image"] ? chat["url-image"].length : 0);
      this.conversacionSelect = chat;
      this.fEditComment.description.setValue(chat["mensaje"]);
      this.modalService.open(this.agregarImagenes, { windowClass: 'animated fadeInDown', size: 'lg' })
      if(chat["url-image"]){
        this.createFile(chat["url-image"]);
      }
    }

    async createFile(element){
      this.filesEdit = []
      for(let i = 0; i < element.length; i++){
        let response = await fetch(element[i]);
        let data = await response.blob();
        let metadata = {
          type: 'image/jpeg'
        };
        let final = element[i].substr(element[i].lastIndexOf('/') + 1);
        let file = new File([data], final, metadata);
        this.filesEdit.push(file)
      }
    }

    showModal() {
      this.modalService.open(this.agregarImagenes, { windowClass: 'animated fadeInDown', size: 'lg' })
    }
  
    closeSoporteModal() {
      this.modalService.dismissAll();
    }

    showSidebar(event) {
      const toggleIcon = document.getElementById('sidebar-left');
      const toggle = document.getElementById('app-content-overlay');
      if (event.currentTarget.className === 'mt-2 sidebar-toggle col-md-1 d-block d-md-inline-block d-lg-none') {
        this.renderer.addClass(toggleIcon, 'show');
        this.renderer.addClass(toggle, 'show');
      } else if (event.currentTarget.className === 'sidebar-close-icon' || 'app-content-overlay') {
        this.renderer.removeClass(toggleIcon, 'show');
        this.renderer.removeClass(toggle, 'show');
      }
    }
      showCompose(event){
        const toggleIcon = document.getElementById('compose-sidebar');
        const toggleOverlay = document.getElementById('app-content-overlay');
        if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
          this.renderer.removeClass(toggleIcon, 'show');
          this.renderer.removeClass(toggleOverlay, 'show');
        }
      }
      showComposeSidebar(event){
        const toggleIcon = document.getElementById('compose-sidebar');
        const toggleSidebar = document.getElementById('sidebar-left');
        const toggleOverlay = document.getElementById('app-content-overlay');
        if (event.currentTarget.className === 'btn clr-btn btn-glow btn-block my-2 compose-btn') {
          this.renderer.addClass(toggleIcon, 'show');
         this.renderer.removeClass(toggleSidebar, 'show');
          this.renderer.addClass(toggleOverlay, 'show');
        } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
          this.renderer.removeClass(toggleIcon, 'show');
         }
      }
  
      showEmail(event, chat,tipo='2') {
  
        tipo =='2'? chat.user = chat.user : '';
        
        this.currentMessage = chat
        const toggleIcon = document.getElementById('app-details');
        if (event.currentTarget.className === 'media-body d-flex align-items-center') {
          this.renderer.addClass(toggleIcon, 'show');
        } else if (event.currentTarget.className === 'ficon feather ft-chevron-left font-medium-4 align-middle') {
          this.renderer.removeClass(toggleIcon, 'show');
        }
      }
      
      validatorExt(arrayExt): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          const result = this.validateExt(control.value, arrayExt)
          return result?{ext: {value: control.value}}:null
        }
      }

      validateExt(fileName, ext) {
        const availableExt = ext
        for(let ext of availableExt) {
          const validate = fileName.includes(ext)
          if (validate) {
            return  true;  
          }
        }
        return false
      }

  }
  

