<div class="app-content content">
  <div class="content-header row mb-1">
  </div>
  <div class="content-body">
    <!-- barra de busqueda -->
    <article class="col-12 pb-2">
      <fieldset class="form-group position-relative has-icon-left m-0">
        <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
        placeholder="Buscar por Folio, Cliente, Proyecto, Tipo y Fecha aquí ...">
        <div class="form-control-position">
          <i class="feather ft-search"></i>
        </div>
      </fieldset>
    </article>
    <!-- Tabla de solicitudes  -->
    <div class="row match_height">
      <div id="recent-transactions" class="col-12">
        <div class="card ml-1 mr-1">
          <div class="mt-2 d-flex justify-content-between">
            <h4 class="ml-1">Seguimiento a soporte</h4>
            <div class="heading-elements invoice">
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-md clr round btn-min-width pull-right dropdown-toggle mr-1"  id="dropdownBasic1"
                ngbDropdownToggle>Filtrar Estatus</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button (click)="filtrar('Todos')" class="dropdown-item">Todos</button>
                <button (click)="filtrar('Revision')"class="dropdown-item">Revisión</button>
                <button (click)="filtrar('Finalizada')"class="dropdown-item">Finalizada</button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-1 mt-1" *ngIf="contenido!=undefined">
            
            <div class="table table-striped table-bordered add-rows">
              <ngx-datatable class="bootstrap"  [headerHeight]="'auto'" [limit]="10" [footerHeight]="40" [rowHeight]="'auto'" [rows]="contenidoActual"
               [columnMode]="'flex'" fxFlex="auto" >

                <ngx-datatable-column [draggable]="false" name="Folio" prop="folio" [maxWidth]="60" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row"   let-value="value">
                      <div (click)="detalles(row, LargeModelContent)" >{{value}}</div> 
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Cliente" prop="cliente.title" [maxWidth]="280" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div (click)="detalles(row, LargeModelContent)" class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Proyecto" prop="proyecto" [minWidth]="100" [flexGrow]="">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row, LargeModelContent)" class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo" [minWidth]="90" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row, LargeModelContent)" class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-left' [resizeable]="false" [draggable]="false" name="Descripción" prop="descripcion"   [minWidth]="350" [flexGrow]="3.4">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row, LargeModelContent)" class="text-left cursor" [innerHTML]="value"></div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Fecha Consulta" prop="fecha-consulta" [minWidth]="120" [flexGrow]="1.5">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row, LargeModelContent)" class="w-100 center-items" >{{value | date:'dd-MM-YYYY'}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus" [minWidth]="90" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row, LargeModelContent)"class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <!-- <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [minWidth]="120" [flexGrow]="1">
                  <ng-template  ngx-datatable-cell-template let-row="row" let-value="value" class="mr-1">
                      <div class="w-100 center-items">
                        
                        <i class="font-medium-3 la la-trash danger cursor" title="Eliminar" (click)="eliminar(EliminarFormContent, row)"></i>
                      </div>
                  </ng-template>
                </ngx-datatable-column> -->


              </ngx-datatable>

            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <ng-template class="modal text-left" #LargeModelContent let-c="close" let-d="dismiss">
    <div class="modal-lg">
      <div class="modal-header">
        <h3 id="myModalLabel23">Conoce los detalles del reporte</h3>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
          <span class="icon-close-md" aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <!-- titulos del modal en info -->
        <div class="mt-2" matchHeight="card">
          <div class="form-body row px-2">
            <div class="col-md-6 col-lg-6">
              <div class="card-header bg mb-1">
                <h3 class="card-title text-center txt">Detalles</h3>
              </div>
              <div class="">
                <div class="form-group">
                    <div class="list-group-item  flex-column align-items-start">
                      <h5 class="text-bold-600">Folio</h5>
                      <p class="clr-txt">{{selectedRow.folio}}</p>
                      <h5 class="text-bold-600">Estatus</h5>
                      <p class="clr-txt">{{selectedRow.estatus}}</p>
                      <h5 class="text-bold-600">Cliente</h5>
                      <p class="clr-txt">{{selectedRow.cliente['title']}}</p>
                      <h5 class="text-bold-600">Proyecto</h5>
                      <p class="clr-txt">{{selectedRow.proyecto}}</p>
                      <h5 class="text-bold-600">Tipo de soporte</h5>
                      <p class="clr-txt">{{selectedRow.tipo}}</p>
                      <h5 class="text-bold-600">Descripción</h5>
                      <p class="clr-txt" [innerHTML]="selectedRow.descripcion"></p>
                      <h5 class="text-bold-600">Fecha de consulta</h5>
                      <p class="clr-txt">{{selectedRow['fecha-consulta'] | date:'dd-MM-YYYY'}}</p>
                      </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="card-header bg mb-1">
                  <h3 class="card-title text-center txt">Imágenes</h3>
                </div>
                <div class="card-content collapse show">

                  <ngb-carousel [pauseOnHover]="true" interval="0">
                    <ng-template ngbSlide class="col-lg-4 col-12" *ngFor="let image of selectedRow['url-image']">
                      <figure class="effect-zoe clr-hover">
                        <img [src]="image" height="250" width="400">
                      </figure>
                    </ng-template>
                  </ngb-carousel>

                  <div class="card-content">
                    <div class="card-body text-center">
                      <div *ngIf="hasCalificacion">
                        <p>Valoración <span><img [src]="ratingUrl" alt=""></span></p>
                      </div>
                      <button *ngIf="!hasCalificacion" type="button"class="btn clr-btn btn-min-width ml-1 mt-2 mb-1" [routerLink]="['/chat-soporte']" (click)="d('Close modal')">
                        Ver Seguimiento
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- carrusel de imágenes -->
         
          
        </div>
    </div>
  </ng-template>
  <ng-template class="modal text-left" #EliminarFormContent let-d="dismiss">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Eliminar</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span class="icon-close-md" aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">
            <ng-container mCardBody>
              <div class="modal-body icon-alert mt-2 pb-2">
                <img src="../../../../assets/images/encuesta/eliminar.svg" alt="alerta" width="90" >
            </div>
            <h3 class="icon-alert mb-3">¿Desea eliminar el reporte de soporte?</h3>
            </ng-container>
          
        </div>
        <div class="modal-footer">
          <button type="button" (click)="borrarAceptar()" class="btn btn-danger">Eliminar</button>
        </div>
      </form>
    </div>
  </ng-template>
