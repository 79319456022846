<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-12">
                        <ng-content select="[main]"></ng-content>
                        <article class="card">
                            <div class="card-body">
                                <ng-content></ng-content>
                            </div>
                        </article>
                        <ng-content select="[footer]"></ng-content>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>