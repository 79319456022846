import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ConectorApiService } from './conector-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InterceptorApiService implements HttpInterceptor {

  private countRequest = 0;
  constructor(private router: Router, private auth: AuthenticationService, private spinnerService: NgxSpinnerService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const nuevospinner = document.body;
    
    // Token
    const token = localStorage.getItem('token_SD');

    // Lista de urls sin token
    const exceptions = [
      'login'
    ];
    const imagesUrlList = [
      'upload-files',
    ]

    // valida las excepciones
    const found = exceptions.find(I => request.url.includes(I));
    const isImageUrl = imagesUrlList.find ( urlImage => request.url.includes(urlImage))
    /* si no esta en lista de exeptions */
    if (request.url.includes('create-conversacion')) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${ token }`
        }
      });
    } else if(isImageUrl){
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${ token }`
        }
      });
      // console.log("🚀 ~ file: interceptor-api.service.ts ~ line 45 ~ InterceptorApiService ~ intercept ~ request", request)
    } else if (!found) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${ token } `
        }
      });
      // console.log("🚀 ~ file: interceptor-api.service.ts ~ line 45 ~ InterceptorApiService ~ intercept ~ request", request)
    }
    else{
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json'
        }
      });

    }

    if (!this.countRequest) {
      nuevospinner.classList.add('body-spinner');
      this.spinnerService.show();
    }

    this.countRequest++;
    return next.handle(request).pipe(

      catchError(error => {

        if (error instanceof HttpErrorResponse && error.status === 403 || error.status === 401) {

          alert('error');

          this.router.navigateByUrl("/login");

        } else {
            console.info('ErrorHG', error);
            return throwError(error);
        }

      }), finalize( () => {
        this.countRequest--;
          if (!this.countRequest) {
            nuevospinner.classList.remove('body-spinner');
            this.spinnerService.hide()
          }
      })

    );

  }

}
