import { SdServicesService } from './../../../../services/sd-services.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'detail-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.css']
})
export class DetailsModalComponent implements OnInit {

  @ViewChild('DetailModal') modal: NgbModal
  @Input() info: any
  @Input() router: any
  calificacionUrl: string
  hasCalificacion: boolean
  imageArr: any
  imgRender: any
  contador: number
  constructor(
    private _modalService: NgbModal,
    private _router: Router,
    private _sdService: SdServicesService
  ) { 
    this.contador = 1;
  }

  ngOnInit(): void {
    this.info = this.info == undefined
      ?{}
      :this.info
  }

  navigateTo(){
    localStorage.setItem('solicitud', JSON.stringify(this.info))
    this._modalService.dismissAll(this.modal)
    if(this.router != undefined){
      this._router.navigate([this.router])
    }
  }
  showModal() {
      setTimeout( ()=>{
        this.imageArr = []
        if(this.info['url-image']!=undefined){
          this.imageArr = this.info['url-image']?.slice()
          this.contador = this.imageArr.length-1;
          this.imgRender = this.imageArr[this.contador];
        }

        this.hasCalificacion = this.info.hasOwnProperty('calificacion')
        ?true
        :false
        if (this.info.hasOwnProperty('calificacion')) {
          this.calificacionUrl = 
          `../../../../../assets/images/encuesta/${
            this.transformText(this.info['calificacion'])
          }-selected.svg`
        }
        this._modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
      }
      ,100)
  }
  transformText(text: string): string{
    let res = text
    .toLowerCase()
    .split(' ')
    .join('-')
    return res
  }
}
