<ng-template class="modal text-left" #CreateModal let-c="close" let-d="dismiss">
   <div class="modal-lg">
     <div class="modal-header">
       <h4 id="myModalLabel23">Crear Consultor</h4>
       <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
         <span class="icon-close-md" aria-hidden="true">&times;</span>
       </button>
     </div>
     <!-- titulos del modal en info -->
     <div class="p-2">
       <form (submit)="onSubmitCrear($event)">
         <div class="px-1 pb-2 row mrg" matchHeight="card">
            <label for="Descripcion">Nombre</label>
            <input data-validation-required-message="Please enter your username." id="name" class="form-control" placeholder="Escriba el Nombre Completo del Cosultor" required>
          </div>
         <div class="px-1 pb-2 row mrg" matchHeight="card">
           <label for="Descripcion">Correo Electrónico</label>
           <input id="email" class="form-control" placeholder="Escriba el Correo Electrónico del Consultor" required>

         </div>
         <div class="px-1 pb-2 row mrg" matchHeight="card">
           <label for="Descripcion">Contraseña</label>
           <input id="password" type="password" class="form-control" placeholder="Escriba una contraseña" required>
         </div>
         <div class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="estatus">Estatus</label>
          <select id="estatus" class="form-control" required>
            <option value="">Seleccionar</option>
            <option value="1">Activo</option>
            <option value="2">Inactivo</option>
          </select>
         </div>
         <div class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="noficacion">Notificación</label>
          <select id="noficacion" class="form-control">
            <option value="1">Activa</option>
            <option value="2">Inactiva</option>
          </select>
         </div>

         <div class="form-actions text-right">
           <button type="submit" class="btn clr">
            Crear
           </button>
         </div>
       </form>
     </div>
   </div>
 </ng-template>