
import { ModalFolioComponent } from './bzr/modal-folio/modal-folio/modal-folio.component';
import { CatOptionsComponent } from './bzr/cat-options/cat-options/cat-options.component';
import { CatContenidosComponent } from './bzr/cat-contenidos/cat-contenidos/cat-contenidos.component';
import { InterceptorApiService } from './services/interceptor-api.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DashboardComponent } from './bzr/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { DetailsModalComponent } from './sd/utils/modals/details-modal/details-modal.component';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig
} from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgbModule,
  NgbCarouselConfig,
  NgbModalConfig
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthGuard } from './_guards/auth.guard';
import { AlertComponent } from './_helpers/alert.component';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';
import { ChartApiService } from './_services/chart.api';
import { TableApiService } from './_services/table-api.service';
import { ApplicationApiService } from './_services/application-api.service';
import { QuillInitializeServiceService } from './_services/quill-initialize-service.service';

// Routing
import { routing } from './app.routing';
// Components
import { AppComponent } from './app.component';
import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';
import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { RegisterComponent } from './register';
import { SocialSigninComponent } from './social-signin/social-signin.component';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { NavbarService } from './_services/navbar.service';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';
// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { PartialsModule } from './content/partials/partials.module';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { UserService } from './_api/user/user.service';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';;
import { BizNavigationComponent } from './_layout/navigation/biz-navigation/biz-navigation.component';
import { BizHeaderComponent } from './_layout/header/biz-header/biz-header.component';
import { FilterCheckedPipe, UserPipesPipe } from './services/user-pipes.pipe';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AuthenticationService } from './services/authentication.service';;
import { SdLayoutComponent } from './_layout/sd-layout/sd-layout.component'
import { ModalsModule } from './sd/utils/modals/modals.module'
import { LayoutsModule } from './sd/utils/layouts/layouts.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClienteDashboardComponent } from './sd/cliente/cliente-dashboard/cliente-dashboard.component';
import { ChatClienteComponent } from './sd/cliente/chat-cliente/chat-cliente.component'
import { ArchivadosClienteComponent } from './sd/cliente/archivados-cliente/archivados-cliente.component'

import { ChatSoporteComponent } from './sd/soporte/chat-soporte/chat-soporte.component';
import { SolicitudesSoporteComponent } from './sd/soporte/solicitudes-soporte/solicitudes-soporte.component';

import { UsuariosAdminComponent } from './sd/admin/usuarios-admin/usuarios-admin.component';
import { SolicitudesAdminComponent } from './sd/admin/solicitudes-admin/solicitudes-admin.component';
import { CatalogoProyectosComponent } from './sd/admin/catalogo-proyectos/catalogo-proyectos.component';
import { CatalogoConsultoresComponent } from './sd/admin/catalogo-consultores/catalogo-consultores.component';

import { Dropzone2Component } from './dropzone2/dropzone2.component'
import { QuillModule } from 'ngx-quill';
@NgModule({
  imports: [
    QuillModule.forRoot(),
    LayoutsModule,
    ModalsModule,
    NgxDropzoneModule,
    RouterModule,
    BrowserModule,
    NgxDatatableModule,
    PartialsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    UiSwitchModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatchHeightModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    routing,
    // Settings modules
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    LoadingBarRouterModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    CurrencyMaskModule
  ],
  declarations: [
    AppComponent,
    Dropzone2Component,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    BizHeaderComponent,
    FooterComponent,
    AppNavigationComponent,
    BizNavigationComponent,
    AlertComponent,
    RegisterComponent,
    SocialSigninComponent,
    LoginComponent,
    ChangelogComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    BlockTemplateComponent,
    FullLayoutComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    DetailsModalComponent,

    // service desk components

    DashboardComponent,
    UserPipesPipe,
    FilterCheckedPipe,
    CatContenidosComponent,
    CatOptionsComponent,
    ModalFolioComponent,
    SdLayoutComponent,
    ClienteDashboardComponent,
    ChatClienteComponent,
    ArchivadosClienteComponent,

    SolicitudesSoporteComponent,
    ChatSoporteComponent,
    
    UsuariosAdminComponent,
    SolicitudesAdminComponent,
    CatalogoConsultoresComponent,
    CatalogoProyectosComponent,
  ],
  providers: [
    AuthGuard,
    ChartApiService,
    AlertService,
    NavbarService,
    TableApiService,
    ApplicationApiService,
    QuillInitializeServiceService,
    AuthService,
    AuthenticationService,
    UserService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    NgbCarouselConfig,
    NgbModalConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorApiService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
