import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import JsBarcode from 'jsbarcode/bin/JsBarcode'
import moment from 'moment';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})

export class ConectorApiService {

  internalSocket: BehaviorSubject<any> = new BehaviorSubject(null);

  internalSuccess: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }

  login(data) {
    return this.http.post(`${environment.api}/login`, data);
  }

  getSolicitudes() {
    return this.http.get(`${environment.api}/get-solicitudes`);
  }
  putSolicitudes(data) {
    //para asignar proyecto y consultor
    return this.http.post(`${environment.api}/actualizar-solicitudes`,data);
  }
  putProyectoUsuario(data) {
    return this.http.post(`${environment.api}/editar-proyecto-usuario`,data);
  }
  putConsultorUsuario(data) {
    return this.http.post(`${environment.api}/editar-consultor-usuario`,data);
  }
  putEstatusUsuario(data) {
    //1 para activo
    //2 para inactivo
    return this.http.post(`${environment.api}/editar-estatus-usuario`,data);
  }
  
  //Proyectos
  getProyectos(){
    console.log('get')
    return this.http.get(`${environment.api}/get-proyectos`)
  }
  getProyectosEmpresa(data){
    return this.http.post(`${environment.api}/get-proyectos-empresa`, data)
  }
  postProyectos(data){
    return this.http.post(`${environment.api}/crear-proyecto`, data)
  }
  updateProyectos(data){
    return this.http.post(`${environment.api}/editar-proyecto`, data)
  }
  deleteProyectos(nombreProyecto){
    return this.http.post(`${environment.api}/borrar-proyecto`, nombreProyecto)
  }
  getConsultores(){
    return this.http.get(`${environment.api}/get-consultor`)
  }
  getClientes(){
    return this.http.get(`${environment.api}/get-clientes`)
  }
  getArchivo(){
    return this.http.get(`${environment.api}/get-solicitudes-archivadas`)
  }
  getConversacion(data){
    return this.http.post(`${environment.api}/get-conversacion`,data)
  }
  uploadImage(dataImage){
    return this.http.post(`${environment.api}/upload-files`, dataImage);
  }
  deleteImages(dataImage){
    return this.http.post(`${environment.api}/delete-files`, dataImage);
  }
  createNewMessage(data){
    return this.http.post(`${environment.api}/create-conversacion`, data);
  }
  editNewMessage(data){
    return this.http.post(`${environment.api}/update-conversacion`, data);
  }
  createNewSolicitude(data){
    return this.http.post(`${environment.api}/create-solicitudes`, data);
  }
  solicitudesActions(data, action){
    data['acciones'] = action
    console.log(data)
    return this.http.post(`${environment.api}/solicitudes-acciones`, data);
  }
  solicitudesFiltrar(data){
    return this.http.post(`${environment.api}/get-solicitudes-filter`, data);
  }
  recuperarPass(data){
    return this.http.post(`${environment.api}/password-recover`, data);
  }

  editar(option,data){
    const optionObj = {
      proyecto: 'editar-proyecto',
      consultor: 'edit-soporte'
    }
    const optionURL = optionObj[option]
    console.log(`${environment.api}/${optionURL}`)
    return this.http.post(`${environment.api}/${optionURL}`, data);
  }
  borrar(option, data){
    const optionObj = {
      proyecto: 'borrar-proyecto',
      consultor: 'delete-soporte',
    }
    const optionURL = optionObj[option]
    return this.http.post(`${environment.api}/${optionURL}`, data);
  }
  crear(option, data){
    const optionObj = {
      proyecto: 'crear-proyecto',
      consultor: 'create-soporte'
    }
    const optionURL = optionObj[option]
    console.log(optionURL)
    return this.http.post(`${environment.api}/${optionURL}`, data);
  }
  asignarProyecto(data){
    return this.http.post(`${environment.api}/editar-proyecto-usuario`, data);
  }
  asignarConsultor(data){
    return this.http.post(`${environment.api}/editar-consultor-usuario`, data);
  }
  asignarEstatus(data){
    return this.http.post(`${environment.api}/editar-estatus-usuario`, data);
  }
  finalizarSolicitud(data){
    return this.http.post(`${environment.api}/finalizar-solicitud`, data);
  }
  actualizarSolicitud(data){
    return this.http.post(`${environment.api}/actualizar-solicitudes`, data);
  }
  actualizarImagenSolicitud(data){
    return this.http.post(`${environment.api}/actualizar-imagenes-solicitudes`, data);
  }
  createClient(data){
    return this.http.post(`${environment.api}/create-cliente`, data);
  }

  cargandoDatos(){

    Swal.fire({
      title: 'Cargando contenido',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
        backdrop: `
        rgba(255, 255, 255)
        url("/assets/l2.gif") 
        center
        no-repeat
      `,
      customClass: {
        container: 'swal2-container-position'
      }
    })

  }

  cerrarCargandoDatos(){
    Swal.close()

  }

  mensajeError(mensaje){

    Swal.fire({
      icon: 'error',
      text: mensaje
    })

  }

}
