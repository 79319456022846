import { ConectorApiService } from './../../../../services/conector-api.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const $ = (query)=>document.querySelector(query)

@Component({
  selector: 'app-calificacion-modal',
  templateUrl: './calificacion-modal.component.html',
  styleUrls: ['./calificacion-modal.component.css']
})
export class CalificacionModalComponent implements OnInit {

  @ViewChild('ModalCalification') modal: NgbModal
  @Input() info
  selectedUrl: string
  calificacionURL
  constructor(
    private _modalService: NgbModal,
    private _router: Router,
    private _service: ConectorApiService,
    private redirect: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  showModal() {
    this._modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }

  onSubmit(e) {

    e.preventDefault()
    const $formElements = e.target.elements
    const formValue = $formElements.rating.value
    let path2 = '';
    this.redirect.url.subscribe( (res: any) =>{
      path2 = res[0].path
    })
    
    if (formValue.length > 0) {
      const data = {
        id: this.info['item_id'],
        calificacion: formValue
      }
      this._service.finalizarSolicitud(data).subscribe( res=>{
        path2 == "chat-soporte" ? this._router.navigate(['/soporte']) : this._router.navigate(['/cliente'])
        // this._router.navigate(['/cliente'])
      })
    }
    this._modalService.dismissAll()
  }

}
