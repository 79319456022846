import { ConectorApiService } from './../../../../services/conector-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'crear-soporte-modal',
  templateUrl: './crear-soporte-modal.component.html',
  styleUrls: ['./crear-soporte-modal.component.css']
})
export class CrearSoporteModalComponent implements OnInit {
  @ViewChild('CreateModal') modal: NgbModal
  constructor(
    private modalService: NgbModal,
    private _service: ConectorApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        this.modalService.dismissAll()
    });
  }

  onSubmitCrear(e){    
    e.preventDefault()
    const form = e.target

    const data = {
      nombre: form[0].value,
      email: form[1].value,
      password: form[2].value,
      estatus: form[3].value,
      notificacion: form[4].value
    }

    this._service.crear('consultor', data).subscribe( res=>{
      console.log(res)
      this.reloadCurrentRoute();
    })

  }

  show(){
    this.modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'md' })
  }

}
