<ng-template class="modal text-left" #ModalCalification let-c="close" let-d="dismiss">
    <div class="modal-lg">
      <div class="modal-header">
        <h3 id="myModalLabel23">Calificación de Seguimiento</h3>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
          <span class="icon-close-md" aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- titulos del modal en info -->
        <div class="p-2">
          <form (submit)="onSubmit($event)">
            <div class="px-1 pb-0 row mrg" matchHeight="card">
              <h4 class="text-center w-100">¿Qué tan satisfecho estas con el servicio de soporte?</h4>
                <div class="card-body pb-0">
                  <div class="form-group row justify-content-around">
                    <label class="radio muy-insatisfecho">
                      <input type="radio" name="rating" value=1 required> 
                      <span class="rating__img"></span>
                      <p class="text-center">Muy Insatisfecho</p>
                    </label>
                    <label class="radio insatisfecho">
                      <input type="radio" name="rating" value=2>
                      <span class="rating__img"></span>
                      <p class="text-center">Insatisfecho</p>
                    </label>
                    <label class="radio neutral">
                      <input type="radio" name="rating" value=3>
                      <span class="rating__img"></span>
                      <p class="text-center">Neutral</p>
                    </label>
                    <label class="radio satisfecho">
                      <input type="radio" name="rating" value=4>
                      <span class="rating__img"></span>
                      <p class="text-center">Satisfecho</p>
                    </label>
                    <label class="radio muy-satisfecho">
                      <input type="radio" name="rating" value=5>
                      <span class="rating__img"></span>
                      <p class="text-center">Muy Satisfecho</p>
                    </label>
                  </div>
                </div>
            </div>
            <div class="form-actions text-right">
              <button type="submit" class="btn clr-btn">
                Aceptar
              </button>
            </div>
          </form>
        </div>
    </div>
  </ng-template>
