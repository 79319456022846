import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConectorApiService } from './../services/conector-api.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import Swal from 'sweetalert2';
import { NgBlockUI, BlockUI } from 'ng-block-ui';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI('basicAlerts') blockUIBasicAlerts: NgBlockUI;
  @BlockUI('dismissibleAlerts') blockUIDismissibleAlerts: NgBlockUI;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  recoverEmail = ''
  public isDangerClosed2 = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2,
    private conector: ConectorApiService,
    private modalService: NgbModal,
    private _service: ConectorApiService
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    localStorage.setItem('logged', 'no')
    // Remember Me
    if (localStorage.getItem('remember')) {
      this.renderer.removeClass(document.body, 'bg-full-screen-image');
      localStorage.removeItem('currentLayoutStyle');
      this.router.navigate(['/dashboard/sales']);
    } else if (localStorage.getItem('currentUser')) {
      // Force logout on login if not remember me
      this.authService.doLogout();
      this.isPageLoaded = true;
    } else {
      this.isPageLoaded = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  acceder(){
    localStorage.removeItem('nombre')
    localStorage.removeItem('perfil')
    localStorage.removeItem('empresa')

    this.submitted = true;
    //REMOVER ESTA PARTE CUANDO EL SE HAGA LA AUTENTICACION
    localStorage.setItem('nombre', this.f.email.value)
    localStorage.setItem('perfil', this.f.email.value)
    localStorage.setItem('empresa', this.f.email.value)

    this.router.navigate(['cliente']);
    //
  }

  tryLogin() {
    this.submitted = true;

    
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const value = {
      email: this.f.email.value,
      password: this.f.password.value
    };

    console.log(value)

    this.conector.login(value).subscribe((e: any) => {

      if (e.status_code == 401) {
        this.alertService.error("Acceso invalido");
      }
      if(e.ok){
        if (e.hasOwnProperty("access_token")) {

          localStorage.setItem('token_SD', e.access_token);
          const perfil = e.perfil.toLowerCase()
          localStorage.setItem('perfil', perfil);
          const navigateObj = {
            cliente: '/cliente',
            soporte: '/soporte',
            administrador: '/solicitudes-admin'
          }
          localStorage.setItem('nombre',e.nombre)
          localStorage.setItem('empresa',e.empresa)
          localStorage.setItem('proyectos',e.proyecto)
  
          this.router.navigate([navigateObj[perfil]]);
          this.submitted = false;
  
        } else {
          this.alertService.error("Acceso invalido");
          this.submitted = false;
        }
      }else{
        Swal.fire({
          icon: 'error',
          text: e.msg
        })
      }
      console.log(e);
      this.isDangerClosed2 = false;
      setTimeout(() => {
        document.getElementById('alerta').innerHTML = e.msg;
      }, 200);
     
    });

  }
  addCheckbox(event) {
    const toggle = document.getElementById('icheckbox');
    if (event.currentTarget.className === 'icheckbox_square-blue') {
      this.renderer.addClass(toggle, 'checked');
    } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
      this.renderer.removeClass(toggle, 'checked');
    }
  }
  setUserInStorage(res) {
    if (res.user) {
      localStorage.setItem('currentUser', JSON.stringify(res.user));
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
  }
  recoverPass(e,modal){
    e.preventDefault()
    this.modalService.open(modal, { windowClass: 'animated fadeInDown' })
  }
  recover(){
    const data = {'email': this.recoverEmail}
    this._service.recuperarPass(data).subscribe( (res)=>{
      console.log(res)
      this.modalService.dismissAll()
    })
  }
}
