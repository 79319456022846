<ng-template class="modal text-left" #InfoModal let-c="close" let-d="dismiss">

    <div class="modal-lg">
      <div class="modal-header d-flex align-items-center">
        <h3 class="modal-title">Bienvenido</h3>
        <button type="button" class="close sd-close" aria-label="Close" (click)="d('Cross click')">
          <span class="icon-close-md" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body row">
        <div class="col-8 text-left">
          <article class="">
            <h3>Como funciona el servicio de Service Desk</h3>
            <p>El servicio de Service Desk prestará la ayuda necesaria para resolver cualquier asunto con eficacia en el menor tiempo posible</p>
          </article>
          <article class="">
            <h3 class="txt-title">Soporte Multifuncional</h3>
            <ul class="card__list">
              <li>
                <p>Soporte Técnico</p>
              </li>
              <li>
                <p>Base de datos</p>
              </li>
              <li>
                <p>Marketing</p>
              </li>
              <li>
                <p>Funciones financieras</p>
              </li>
            </ul>
          </article>
        </div>
        <div class="col-4 image-right center-items">
          <img src="../../../../assets/question.svg" alt="">
        </div>
       
      </div>
      <div class="modal-footer row justify-content-center">
        <button (click)="d('Close modal')" type="button" class="px-3 btn clr-btn btn-min-width">Aceptar</button>
      </div>
    </div>
    
        
  </ng-template>
