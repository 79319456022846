
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCSG-EGU2RLITgpjf_ogf1w47edC_DXlWc',
    authDomain: 'evolvision-rnd.firebaseapp.com',
    databaseURL: 'https://evolvision-rnd.firebaseio.com',
    projectId: 'evolvision-rnd',
    storageBucket: 'evolvision-rnd.appspot.com',
    messagingSenderId: '890895206035',
    appId: '1:890895206035:web:a6d0e1574d59ffe40bef66'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  //produccion API
  //api: 'https://servicedesk.dsindigo.com:8455'
  // api: 'http://localhost:5000'
  api: 'https://servicedesk.dsindigo.com:8000'

};