<ng-template class="modal text-left" #DetailModal let-c="close" let-d="dismiss">
    <div class="modal-lg">
      <div class="modal-header">
        <h4 class="negritaModal" id="myModalLabel23">Conoce los detalles de su soporte</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
          <span class="icon-close-md" aria-hidden="true">&times;</span>
        </button>

      </div>
      <!-- titulos del modal en info -->
        <div class="row mrg" matchHeight="card">
          <div class="col-md-6 col-lg-6">
            <div class="card">
              <div class="card-header bg">
                <h3 class="card-title text-center txt">Detalles</h3>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="list-group">
                    <div class="flex-column align-items-start">
                      <div class="d-flex justify-content-between">
                        <div>
                          <h5 class="text-bold-600">Folio</h5>
                          <p class="clr-txt">{{info.folio}}</p>
                        </div>
                        <div>
                          <h5 class="text-bold-600">Estatus</h5>
                          <p class="clr-txt">{{info.estatus}}</p>
                        </div>
                      </div>
                      <h5 class="text-bold-600">Cliente</h5>
                      <p class="clr-txt">{{info.cliente['title']}}</p>
                      <div class="d-flex justify-content-between">
                        <div>
                          <h5 class="text-bold-600">Proyecto</h5>
                          <p class="clr-txt">{{info.proyecto}}</p>
                        </div>
                        <div>
                          <h5 class="text-bold-600">Tipo de soporte</h5>
                          <p class="clr-txt">{{info.tipo}}</p>
                        </div>
                      </div>
                      <h5 class="text-bold-600">Descripción</h5>
                      <div [innerHTML]="info.descripcion"></div>
                      <h5 class="text-bold-600">Fecha de consulta</h5>
                      <!-- <p class="clr-txt">{{info['fecha-consulta'] |  date:'medium' }}</p> -->
                      <p class="clr-txt">{{info['fecha-consulta'] | date:'dd-MM-YYYY' }}</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- carrusel de imágenes -->
          <div class="col-md-6 col-lg-6">
            <div class="card">
              <div class="card-header bg">
                <h3 class="card-title text-center txt">Imágenes</h3>
              </div>
              <div class="card-content" *ngIf="!hasCalificacion && imageArr.length==0">
                <div class="card-body text-center">
               <div *ngIf="hasCalificacion">
                    <p class="d-inline-block">Valoración <img class="mx-1 image_calif" [src]="calificacionUrl" alt=""></p>
                    <p class="d-inline-block">{{info['calificacion']}}</p>
                  </div> 
                  <button *ngIf="!hasCalificacion" type="button"class="btn clr-btn btn-min-width ml-1 mt-2 mb-1" (click)="navigateTo()">
                    Ver Seguimiento
                  </button>
                </div>
              </div>
              <div class="card-content collapse show" *ngIf="imageArr.length>0">
                <ngb-carousel [pauseOnHover]="true" interval="0">
                  <ng-template ngbSlide class="col-lg-4 col-12" *ngFor="let image of imageArr">
                    <img [src]="image" height="250" width="400">
                  </ng-template>
                </ngb-carousel>
                <div class="card-content">
                  <div class="card-body text-center">
                 <div *ngIf="hasCalificacion">
                      <p class="d-inline-block">Valoración <img class="mx-1 image_calif" [src]="calificacionUrl" alt=""></p>
                      <p class="d-inline-block">{{info['calificacion']}}</p>
                    </div> 
                    <button *ngIf="!hasCalificacion" type="button"class="btn clr-btn btn-min-width box-shadow-2 ml-1 mt-2 mb-1" (click)="navigateTo()">
                      Ver Seguimiento
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </ng-template>
