import { ConectorApiService } from './../services/conector-api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from '../_api/user/user.service';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import Swal from 'sweetalert2';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  defaultImage = '../assets/images/portrait/small/default.png';
  empresas:any = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private _service: ConectorApiService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      empresa: ['', [Validators.required]]
    });

    this.userService.getUsers().subscribe(users => {
      this.users = users.map(item => {
        return {
          ...item.payload.doc.data() as {},
          id: item.payload.doc.id
        };
      });
    });

    let hash = {};
    this._service.getProyectos().subscribe( (res:any)=>{
      this.empresas = res.data.map( ele => { return ele["nombre-empresa"]})
      this.empresas = this.empresas.filter((item,index)=>{
        return this.empresas.indexOf(item) === index;
      })
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    console.log(currentUrl);
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
  modalExisteUsuario(){
    Swal.fire({
      icon: 'error',
      text: 'El correo ingresado ya existe.'
    })
    setTimeout(() =>{
      Swal.close();
      this.reloadCurrentRoute();
    },2000)
  }

  tryRegister() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    const data = {
      nombre: this.registerForm.value.firstName,
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      empresa: this.registerForm.value.empresa,
    }

   this._service.createClient(data).subscribe(
      res => {
        res['msg'] == 'El correo ingresado ya existe'? this.modalExisteUsuario() : this.router.navigate(['/login']);
        //this.loading = false;
        //console.log(res);
        //this.errorMessage = '';*/
      },
      err => {
        console.log(err);
        this.loading = false;
        this.alertService.error(err.message);
      }
    );
  }
}
