import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  roleAs: string;

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.info('CC', state.url);
    return this.canActivate(next, state);
    ;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  isAuthenticated() {

    const token = localStorage.getItem('token_i7B0TADH');

    return (token == null) ? false : true;

  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {

    if (this.isAuthenticated()) {

      const userRole = this.getRole();

      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        this.router.navigate(['/login']);
        return false;
      }

      return true;

    }

    this.router.navigate(['/login']);

    return false;

  }

  getRole() {

    this.roleAs = localStorage.getItem('role_i7B0TADH');

    console.info('current_role', this.roleAs);

    return this.roleAs;
  }

}

