<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-body">
      <section id="minimal-statistics">

        <div class="row">

          <div class="col-xl-4 col-md-6 col-12">
            <div class="card" (click)="navigateTo(0)">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="align-self-center">
                      <i class="la la-barcode font-large-3 float-left"></i>
                    </div>
                    <div class="media-body text-right">
                      <h3>Embarques</h3>
                      <span>Ingreso por escaner</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-12">
            <div class="card" (click)="navigateTo(1)">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="align-self-center">
                      <i class="feather ft-box font-large-3 float-left"></i>
                    </div>
                    <div class="media-body text-right">
                      <h3>Generar</h3>
                      <span>Orden de salida</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-12">
            <div class="card" (click)="navigateTo(2)">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="align-self-center">
                      <i class="feather ft-search font-large-3 float-left"></i>
                    </div>
                    <div class="media-body text-right">
                      <h3>Embarques</h3>
                      <span>Búsqueda por orden de salida</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-12">
            <div class="card" (click)="navigateTo(3)">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="align-self-center">
                      <i class="feather ft-map font-large-3 float-left"></i>
                    </div>
                    <div class="media-body text-right">
                      <h3>Envíos</h3>
                      <span>Externos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-12">
            <div class="card" (click)="navigateTo(4)">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="align-self-center">
                      <i class="la la-car font-large-3 float-left"></i>
                    </div>
                    <div class="media-body text-right">
                      <h3>Envíos</h3>
                      <span>Internos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 col-12">
            <div class="card" (click)="navigateTo(5)">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="align-self-center">
                      <i class="feather ft-server font-large-3 float-left"></i>
                    </div>
                    <div class="media-body text-right">
                      <h3>Embarques</h3>
                      <span>Consulta de orden</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>
