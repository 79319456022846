<div class="app-content content">
  <div class="content-body">
    <!-- barra de busqueda -->
    <article class="col-12 pb-2 pt-2">
      <fieldset class="form-group position-relative has-icon-left m-0">
        <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
        placeholder="Buscar por Folio, Consultor, Proyecto, Tipo, Fecha y Estatus aquí ...">
        <div class="form-control-position">
          <i class="feather ft-search"></i>
        </div>
      </fieldset>
    </article>
    <!-- Tabla de solicitudes  -->
    <section id="recent-transactions" class="col-12">
      <article class="card">
        <div class="card-header">
          <h4 class="ml-1">Seguimiento a soporte</h4>
        </div>
          <div class="card-dashboard card-body">
              <ngx-datatable class="bootstrap table-bordered" [limit]="30" [rows]="contenido" [columnMode]="'flex'"
              [headerHeight]="'auto'" [footerHeight]="40" [rowHeight]="'auto'" fxFlex="auto" [perfectScrollbar]="configScroll">
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [minWidth]="70" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items">{{value}}</div> 
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Consultor" prop="consultor" [minWidth]="215" [flexGrow]="1.6">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items" *ngIf="value.title != ''">{{value.title}}</div>
                      <div class="w-100 center-items" *ngIf="value == '' || value.title == '' ">Sin Consultor</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Proyecto" prop="proyecto" [minWidth]="100" [flexGrow]="1.5">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo" [minWidth]="90" [flexGrow]="1.3">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-left' [resizeable]="false" [draggable]="false" name="Descripción" prop="descripcion" [minWidth]="400" [flexGrow]="5.5">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div class="w-100 text-left " [innerHTML]="value"></div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Fecha Consulta" prop="fecha-consulta" [minWidth]="120" [flexGrow]="1.5">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items">{{value | date:'dd-MM-YYYY'}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus" [minWidth]="90" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="1.2" [minWidth]="120"> 
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items"> 
                        <i class="font-medium-2 feather ft-share info mr-1 pl-1 cursor" title="Desarchivar" (click)="activar(row)"></i>
                        <i class="font-medium-3 la la-trash danger cursor" title="Eliminar" (click)="eliminar(EliminarFormContent, row)"></i>
                      </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
      </article>
    </section>
  </div>
</div>

<!-- Modal para eliminar  -->
<ng-template class="modal text-left" #EliminarFormContent let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Eliminar</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
    <form>
      <div class="modal-body">
          <ng-container mCardBody>
            <div class="modal-body icon-alert mt-2 pb-2">
              <img src="../../../../assets/images/encuesta/eliminar.svg" alt="alerta" width="90" >
          </div>
          <h3 class="icon-alert mb-3">¿Desea eliminar el reporte de soporte?</h3>
          </ng-container>
        
      </div>
      <div class="modal-footer">
        <button type="button" (click)="borrarAceptar()" class="btn btn-danger">Eliminar</button>
      </div>
    </form>
  </div>
</ng-template>