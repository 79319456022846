import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userPipes'
})

export class UserPipesPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}


@Pipe({
  name: 'filterNull'
})

export class FilterCheckedPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]) {
    return value.filter( x => x !== null );
  }
}