<div class="app-content content">
  <div class="content-body">
    <!-- barra de busqueda -->
    <article class="col-12 pb-2 pt-2">
      <fieldset class="form-group position-relative has-icon-left m-0">
        <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
        placeholder="Buscar por Folio, Consultor, Proyecto, Tipo, Fecha y Estatus aquí ...">
        <div class="form-control-position">
          <i class="feather ft-search"></i>
        </div>
      </fieldset>
    </article>
    <!-- Tabla de solicitudes  -->
      <section id="recent-transactions" class="col-12">
        <article class="card">
          <div class="card-header">
            <h4 class="ml-1">Seguimiento a soporte</h4>
            <div class="heading-elements invoice">
                <div class="d-inline-block">
                    <button class="btn clr round btn-more-size pull-right mr-1" (click)="openSoporteModal()" id="dropdownBasic1">
                      <i class="feather ft-plus"></i> Nueva Solicitud
                    </button>
                    
                </div>
            </div>
          </div>
          <div class="card-body card-dashboard">
            <ngx-datatable class="bootstrap table-bordered" [limit]="30" [rows]="contenido" [columnMode]="'flex'"
              [headerHeight]="'auto'" [footerHeight]="40" [rowHeight]="'auto'" fxFlex="auto" [perfectScrollbar]="configScroll" >
              <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [minWidth]="70" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row"   let-value="value">
                    <div class="w-100 center-items cursor" (click)="detalles(row)">{{value}}</div> 
                </ng-template>
              </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Consultor" prop="consultor" [minWidth]="215" [flexGrow]="1.6">
                  <ng-template ngx-datatable-cell-template let-value="value">
                      <div class="w-100 center-items cursor" (click)="detalles(row)" *ngIf="!value.title == '' ">{{value.title}}</div>
                      <div class="w-100 center-items cursor" (click)="detalles(row)" *ngIf="!value.title != '' ">No asignado</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Proyecto" prop="proyecto" [minWidth]="100" [flexGrow]="1.5">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row)" class="cursor w-100 center-items">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo" [minWidth]="90" [flexGrow]="1.3">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row)" class="w-100 center-items cursor">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-left' [resizeable]="false" [draggable]="false" name="Descripción" prop="descripcion" [minWidth]="400" [flexGrow]="5.5">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row)" class="w-100 text-left cursor" [innerHTML]="value">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Fecha de Creación" prop="fecha-consulta" [minWidth]="120" [flexGrow]="1.5">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row)" class="cursor w-100 center-items">{{value | date:'dd-MM-YYYY'}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Última Respuesta" prop="ultima-fecha-de-respuesta" [minWidth]="110" [flexGrow]="1.6">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row)"class="cursor w-100 center-items" >{{value | date:'dd-MM-YYYY'}}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus" [minWidth]="90" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-row="row"  let-value="value">
                      <div (click)="detalles(row)" class="cursor w-100 center-items pl-1">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [minWidth]="120" [flexGrow]="1.2">
                  <ng-template headerClass='text-center' ngx-datatable-cell-template let-row="row" let-value="value">
                      <div class="w-100 center-items">
                        <i class="font-medium-2 feather ft-folder info mr-1 pl-1 cursor" title="Archivar" (click)="archivar(row)"></i>
                        <i class="font-medium-3 la la-trash danger cursor" title="Eliminar" (click)="eliminar(EliminarFormContent, row)"></i>
                      </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
        </article>
      </section>
  </div>
</div>

<!-- Modal para eliminar  -->
<ng-template class="modal text-left" #EliminarFormContent let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Eliminar</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
    <form>
      <div class="modal-body">
          <ng-container mCardBody>
            <div class="modal-body icon-alert mt-2 pb-2">
              <img src="../../../../assets/images/encuesta/eliminar.svg" alt="alerta" width="90" >
          </div>
          <h3 class="icon-alert mb-3">¿Desea eliminar el reporte de soporte?</h3>
          </ng-container>
        
      </div>
      <div class="modal-footer">
        <button type="button" (click)="borrarAceptar()" class="btn btn-danger">Eliminar</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- MODAL DE NUEVA SOLICITUD -->
<ng-template class="modal text-left" #SupportModal let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 class="negritaModal" id="myModalLabel23">Nosotros te brindamos el soporte que necesitas</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeSoporteModal()">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>

    </div>
    <!-- Formulario -->
    <div class="mt-2" >
      <form [formGroup]="soporteContact">
        <div class="form-body row px-2">
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Detalles</h3>
            </div>
            <div class="  ">
              <div class="form-group">
                <label for="proyecto">Proyecto</label>
                <select id="proyecto" class="form-control" formControlName="projects"
                  [ngClass]="{ 'is-invalid': submitted && fProject.projects.errors }"required (change)="hideOption()">
                  <option *ngFor="let project of projectsList" value="{{project.proyecto}}">{{project.proyecto}}</option>
                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && fProject.projects.errors"
                  class="invalid-feedback">
                  <div *ngIf="fProject.projects.errors.required">Este campo es requerido</div>
                </small>
              </div>
              <div class="form-group">
                <label for="tipoSoporte">Tipo de Soporte</label>
                <select id="tipoSoporte" class="form-control" formControlName="tipos"
                  [ngClass]="{ 'is-invalid': submitted && fProject.tipos.errors }" required>
                  <option selected hidden value="" disabled="disabled">Asignar Tipo</option>
                  <option *ngFor="let tipo of tiposList, index as i" [value]="tipo.id">{{tipo.tipo}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="Descripcion">Brindanos una Descripción Breve</label>
                  <quill-editor class="content-podio" id="txtContent" [modules]="modulesQuill"
                                formControlName="description">
                                <div quill-editor-toolbar>
                                    <span class="ql-formats">
                                        <button type="button" class="ql-bold" title="Bold">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-stroke"
                                                    d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                </path>
                                                <path class="ql-stroke"
                                                    d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-italic">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line>
                                                <line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-underline">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-stroke"
                                                    d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path>
                                                <rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3"
                                                    y="15"></rect>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">

                                        <button type="button" class="ql-header" value="1">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-fill"
                                                    d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-header" value="2">
                                            <svg viewBox="0 0 18 18">
                                                <path class="ql-fill"
                                                    d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z">
                                                </path>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">

                                        <button type="button" class="ql-list" value="ordered">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line>
                                                <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5">
                                                </line>
                                                <path class="ql-fill"
                                                    d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z">
                                                </path>
                                                <path class="ql-stroke ql-thin"
                                                    d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156">
                                                </path>
                                                <path class="ql-stroke ql-thin"
                                                    d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109">
                                                </path>
                                            </svg>
                                        </button>

                                        <button type="button" class="ql-list" value="bullet">
                                            <svg viewBox="0 0 18 18">
                                                <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line>
                                                <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line>
                                            </svg>
                                        </button>
                                    </span>

                                    <span class="ql-formats">
                                        <select class="ql-align" [title]="'Aligment'">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                                </div>
                            </quill-editor>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Imágenes</h3>
            </div>
            <ngx-dropzone  class="_dropzone" [accept]="'image/*'" [disabled]="isDisabled" (change)="SelectMultipleFile($event)">
              <ngx-dropzone-label>
                  <div class="dz-default dz-message">
                      <span class = "dz-default">Arrastre las imágenes aquí <br> (Maximo {{limiteDeImagenes}})</span>
                  </div>
              </ngx-dropzone-label>
            </ngx-dropzone>

          <div class="images__container1" style="
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            /* justify-content: center; */
            /* margin-left: 12px; */">
            <!-- <div class="card-content" >
              <div class="card-body">
                <ngb-carousel [pauseOnHover]="true" interval="0">
                  <ng-template ngbSlide *ngFor="let f of files">
                    <div class="picsum-img-wrapper d-flex justify-content-center py-0 bg-dark">
                      <ngx-dropzone-image-preview class="carrusel_img" ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="MultiplefilesonRemove(f)">
                      </ngx-dropzone-image-preview>
                    </div>
                    <ngx-dropzone-label class="dropzone1">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ng-template>
                </ngb-carousel>
              </div>
            </div> -->

            <div *ngFor="let f of files" class="mr-1">
              <div class="images__item">
                <ngx-dropzone-image-preview  ngProjectAs="ngx-dropzone-preview"  [file]="f" [removable]="true" (removed)="MultiplefilesonRemove(f)">
                </ngx-dropzone-image-preview>
                <!-- <ngx-dropzone-label class="dropzone1 pl-1">{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="form-actions pr-2 text-right">
          <button [disabled]="disabledCargar" (click)="loadImages()" class="btn clr-btn">
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- FIN DE MODAL DE NUEVA SOLICITUD -->
<welcome-modal></welcome-modal>
<detail-modal [info]="selectedRow" [router]="'/chat-cliente'"></detail-modal>
