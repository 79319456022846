import { Router } from '@angular/router';
import { ConectorApiService } from './../../../services/conector-api.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-archivados-cliente',
  templateUrl: './archivados-cliente.component.html',
  styleUrls: ['./archivados-cliente.component.css']
})
export class ArchivadosClienteComponent implements OnInit {
  
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  public selectedRow
  public contenido
  public contenidoTodo: any;
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : true };
  constructor(private modalService: NgbModal,
    private _service: ConectorApiService,
    private _router: Router) {
      this.contenido=[];
      this.contenidoTodo=[];
      let dataUserVerify = localStorage.getItem('perfil').toLowerCase()
      if (dataUserVerify != 'cliente') {
        _router.navigate(['/login']);
      }

      this.actualizarTabla();

    }

  ngOnInit(): void {

  }

  search(e) {
    if (e.target.value == "") {
      return (this.contenido = this.contenidoTodo);
    }
    const result = this.contenidoTodo.filter((x) =>
      x?.folio?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.proyecto?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.consultor?.toString()?.toLowerCase().includes(e.target.value.toString()?.toLowerCase()) || 
      x?.tipo?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.fechaconsulta?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) || 
      x?.estatus?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) 
    );
    this.contenido = result;
  }

  borrarAceptar(EliminarFormContent){
    console.log('eliminar')
    this._service.solicitudesActions(this.selectedRow, 'Eliminar').subscribe ( (res:any)=>{
      console.log('la solicutd ha sido eliminada')
      if(res.ok){
        this.actualizarTabla();
      }
      this.modalService.dismissAll()
    })
  }

  eliminar(EliminarFormContent, row) {
    this.selectedRow = row
    this.modalService.open(EliminarFormContent, { windowClass: 'animated fadeInDown' });
  }

  activar(row){
    this._service.solicitudesActions(row, 'Activa').subscribe ((res:any)=>{
      console.log('solicitud archivada',res)
      if(res.msg=="Proceso Correcto"){
        this.actualizarTabla();
      }
    })
  }

  actualizarTabla(){
    this._service.getArchivo().subscribe( (res:any)=>{
      if(res.ok){
        let fechaConsulta = res.data.map( d=>{return { ...d, fechaconsulta: d['fecha-consulta']}});
        this.contenido = fechaConsulta
        this.contenidoTodo = this.contenido
      }else{
        this._service.mensajeError(res.msg);
        this.contenido = undefined
        this.contenidoTodo = this.contenido
      }


    })
  }

}
