import { Router } from '@angular/router';
import { AsignarModalComponent } from './../../utils/modals/asignar-modal/asignar-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ConectorApiService } from './../../../services/conector-api.service';

@Component({
  selector: 'app-usuarios-admin',
  templateUrl: './usuarios-admin.component.html',
  styleUrls: ['./usuarios-admin.component.css']
})
export class UsuariosAdminComponent implements OnInit {
  public isActivate: boolean
  @ViewChild(AsignarModalComponent) asignarModal: AsignarModalComponent
  contenido: any
  consultores: any
  proyectos: any
  contenidoTodo
  public selectedRow: any
  selectionType = SelectionType
  constructor(
    private _service: ConectorApiService,
    private _router: Router
  ) {
      let dataUserVerify = localStorage.getItem('perfil').toLowerCase()
      if (dataUserVerify != 'administrador') {
        _router.navigate(['/login']);
      }
      this.isActivate = true
      this.getUsuarios();
  }
  
  ngOnInit(): void {
    
  }

  getUsuarios(){

    this._service.getClientes().subscribe( (response:any)=>{

        if(response.ok){
          this.contenido = response.data
          

          this.contenido.forEach(row => {
            row['estatus'] == 'Activo'
            ?row['isActive'] = true
            :row['isActive'] = false
          });
          this.contenidoTodo = this.contenido
          this._service.getConsultores().subscribe( (res:any)=>{
            if(res.ok){
              this.consultores = res.data
              this._service.getProyectos().subscribe(
                (proyectos:any) => {
                  this.proyectos = proyectos.data;
                }
              )
            }else{
  
              this._service.mensajeError(res.msg);
      
            }
      
          })

        }else{
          
          this._service.mensajeError(response.msg);

        }


    })
  }
  onActiveChange(row){
    const estatus = row.isActive?'Inactivo':'Activo'
    const data = {
      id: row.item_id,
      estatus: estatus
    }
    this._service.asignarEstatus(data).subscribe( res=>{

      location.reload()
    })
  }
  asignarProject(row){
    if(!this.proyectos){
      return;
    }
    const labels = {
      title: 'Asignar Proyecto',
      input: 'Asignar Proyecto',
    }
    const headers = ['Nombre','Correo Electronico']
    this.asignarModal.info = row
    this.asignarModal.labels = labels
    this.asignarModal.headers = headers
    this.asignarModal.option = 'proyecto'
    this.asignarModal.show(this.proyectos)
  }
  asignarConsultor(row){
    if(!this.consultores){
      return;
    }
    const labels = {
      title: 'Asignar Consultor',
      input: 'Asignar Consultor',
    }
    const headers = ['Nombre','Correo Electronico','Proyecto']
    this.asignarModal.info = row
    this.asignarModal.labels = labels
    this.asignarModal.headers = headers
    this.asignarModal.option = 'consultor'
    this.asignarModal.show(this.consultores)
  }

  search(e) {
    if (e.target.value == "") {
      return (this.contenido = this.contenidoTodo);
    }
    const result = this.contenidoTodo.filter((x) =>
      x?.nombre?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.email?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.proyecto?.toString()?.toLowerCase().includes(e.target.value.toString()?.toLowerCase()) || 
      x?.consultor?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
    );
    this.contenido = result;
  }
  
  filtrar(estatus){    
    if (estatus == 'Todos') {
      this.contenido = this.contenidoTodo
    }
    else{
      this.contenido = this.contenidoTodo
      const newArray = []
      this.contenido.filter( row=>{
        const rowEstatus = row.isActive
        if(rowEstatus == estatus){
          newArray.push(row)
        }
      })
      this.contenido = newArray
    }
  }

  async actualizar(){
    let dataUserVerify = localStorage.getItem('perfil').toLowerCase()
    if (dataUserVerify != 'administrador') {
      this._router.navigate(['/login']);
    }
    this.isActivate = true
    this.getUsuarios();

  }

}
