import { Component } from '@angular/core';

@Component({
  selector: 'app-dropzone2',
  templateUrl: './dropzone2.component.html',
  styleUrls: ['./dropzone2.component.css']
})
export class Dropzone2Component {
  
  files: File[] = [];

	onSelect(event) {
		console.log(event);
		this.files.push(...event.addedFiles);
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}
}

