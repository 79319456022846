import { DashboardComponent } from './bzr/dashboard/dashboard.component';
import { BizLayoutComponent } from './_layout/biz-layout/biz-layout.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';
import { AuthenticationService } from './services/authentication.service';
import { SdLayoutComponent } from './_layout/sd-layout/sd-layout.component'

//PANTALLAS CLIENTE
import { ClienteDashboardComponent } from './sd/cliente/cliente-dashboard/cliente-dashboard.component'
import { ChatClienteComponent } from './sd/cliente/chat-cliente/chat-cliente.component'
import { ArchivadosClienteComponent } from './sd/cliente/archivados-cliente/archivados-cliente.component'

import { RegisterComponent } from './register';

//PANTALLAS SOPORTE
import { SolicitudesSoporteComponent } from './sd/soporte/solicitudes-soporte/solicitudes-soporte.component';
import { ChatSoporteComponent } from './sd/soporte/chat-soporte/chat-soporte.component';

//PANTALLAS ADMIN
import { UsuariosAdminComponent } from './sd/admin/usuarios-admin/usuarios-admin.component';
import { SolicitudesAdminComponent } from './sd/admin/solicitudes-admin/solicitudes-admin.component';
import { CatalogoProyectosComponent } from './sd/admin/catalogo-proyectos/catalogo-proyectos.component';
import { CatalogoConsultoresComponent } from './sd/admin/catalogo-consultores/catalogo-consultores.component';

import { Dropzone2Component } from './dropzone2/dropzone2.component'


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'drop',
    component: Dropzone2Component,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: '',
    component: SdLayoutComponent,
    //canActivate: [AuthenticationService],
    children: [
      {
        path: 'cliente',
        component: ClienteDashboardComponent,
      },
      {
        path: 'cliente-archivo',
        component: ArchivadosClienteComponent,
      },
      {
        path: 'chat-cliente',
        component: ChatClienteComponent,
      },
      {
        path: 'soporte',
        component: SolicitudesSoporteComponent,
      },
      {
        path: 'chat-soporte',
        component: ChatSoporteComponent,
      },
      {
        path: 'solicitudes-admin',
        component: SolicitudesAdminComponent,
      },
      {
        path: 'usuarios-admin',
        component: UsuariosAdminComponent,
      },
      {
        path: 'cat-proyectos',
        component: CatalogoProyectosComponent,
      },
      {
        path: 'cat-consultores',
        component: CatalogoConsultoresComponent,
      },
    ]
  },

  { path: '**', redirectTo: 'login' }

];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
