import { ConectorApiService } from './../../../../services/conector-api.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SdServicesService } from '../../../../services/sd-services.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-agregar-imagenes',
  templateUrl: './agregar-imagenes.component.html',
  styleUrls: ['./agregar-imagenes.component.css']
})
export class AgregarImagenesComponent implements OnInit {
  
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;

  @ViewChild('ModalAgregar') modal: NgbModal
  @Input() agregar
  selectedUrl: string
  calificacionURL
  files: File[] = [];  
  isDisabled: any;
  imagesUrls: any = [];
  isFinished: boolean;
  soporteContact: FormGroup;


  constructor(
    private _modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _service: ConectorApiService,
    private _sdService: SdServicesService,
    private _router: Router
  ) {
    console.info(this.agregar);
    this.soporteContact = this.formBuilder.group({
    
      image: new FormControl([], [Validators.required])

    })

   }

  ngOnInit(): void {

  }
  ngOnChanges(): void {

    
  }

  showModal() {
    this._modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }

  closeSoporteModal() {
    this._modalService.dismissAll();
}

SelectMultipleFile(event) {
  console.log(event)
  this.files.push(...event.addedFiles);
    if(this.files.length>9){
      this.files = this.files.slice(0,10)
      this.isDisabled = true
    }
}

MultiplefilesonRemove(event) {
  this.files.splice(this.files.indexOf(event), 1);
  if(this.files.length<=10){
    this.isDisabled = false
  }
}

loadImages(){
  const array = this.files
  this.promiseLoop(array)
}

onSubmitProject(){
  const projectData = {
    "idSolicitud": 1,
    "image": this.imagesUrls
  }
  console.info(projectData);
  return
  
}

async promiseLoop(array){
  const element = array.shift() 
  await this._sdService.promiseImage(element).then( (res)=>{
    console.log(res)
    this.imagesUrls.push(res)
  })
  return array.length>0?this.promiseLoop(array):this.finishLoop()
}

finishLoop(){
  this.isFinished = true
  //this.onSubmitProject()
}  

}
