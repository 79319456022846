<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
         <!-- barra de busqueda -->
         <article class="col-12 pb-2">
          <fieldset class="form-group position-relative has-icon-left m-0">
            <input type="text" class="form-control" id="nombre-search"  (keyup)="search($event)"
            placeholder="Buscar por Folio, Nombre y Correo aquí ...">
            <div class="form-control-position">
              <i class="feather ft-search"></i>
            </div>
          </fieldset>
        </article>
        <!-- Tabla de solicitudes  -->
        <div class="row match_height">
          <section id="recent-transactions" class="col-12">
            <article class="card ml-1 mr-1">
              <div class="card-header">
                <h4 class="ml-1">Catálogo de consultores</h4>
                <div class="heading-elements invoice">
                  <div class="d-inline-block">
                      <button class="btn btn-md clr round btn-min-width pull-right mr-1" id="dropdownBasic1" (click)="openCreate()">
                          <i class="feather ft-plus"></i> Nuevo Consultor
                      </button>
                  </div>
              </div>

              </div>
              <section class="card-body">
                <div class="card-dashboard col-12 table-responsive-md">
                  <ngx-datatable class="bootstrap table-bordered" [limit]="30" [rows]="contenido" [columnMode]="'flex'"
                  [headerHeight]="50" [footerHeight]="40" [rowHeight]="50" fxFlex="auto">

                  <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [width]="'auto'" [flexGrow]="1">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        <div class="w-100 center-items">{{value}}</div> 
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Nombre" prop="nombre" [width]="'auto'" [flexGrow]="2">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        <div class="w-100 center-items">{{value}}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Correo Electrónico" prop="email" [width]="'auto'" [flexGrow]="2">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        <div class="w-100 center-items">{{value}}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column headerClass='text-center' [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="1" headerClass="text-center">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                        <div class="w-100 center-items">
                          <i class="font-medium-2 feather ft-edit-2 info mr-1 cursor" title="Editar" (click)="editar(row)"></i>
                          <i class="font-medium-2 la la-trash danger cursor" title="Eliminar" (click)="borrar(row)"></i>
                        </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
                  </div>
              </section>
            </article>
          </section>
        </div>
      </div>
    </div>
</div>
<admin-modals [info]="selectedRow" [labels]="modalLabels"></admin-modals>
<crear-soporte-modal></crear-soporte-modal>