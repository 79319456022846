import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ConectorApiService } from './../../../../services/conector-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-modals',
  templateUrl: './admin-modals.component.html',
  styleUrls: ['./admin-modals.component.css']
})
export class AdminModalsComponent implements AfterViewInit {
  @Input() info: any
  @Input() labels: any
  @Input() option: any

  @ViewChild('DeleteModal') borrar: NgbModal
  @ViewChild('EditModal') editar: NgbModal
  @ViewChild('EditSolicitude') editarSolicitud: NgbModal
  @ViewChild('CreateModal') crear: NgbModal

  public editInput: any
  public newInput: any
  public projectForm: FormGroup
  public proyectos
  public consultores
  public projectValue
  public ver=false;
  public password:any;
  public estatus: any;
  public notificacion: any;
  passwordBlock="**************"
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private _service: ConectorApiService
    
  ) { }

  ngAfterViewInit(): void {
    this.info = this.info == undefined
      ?{}
      :this.info
    //this.loadProyectos()

  }
  ngOnChanges(){
    this.estatus = this.labels?.estatus == 'Activo' ? 1 : 2;
    this.notificacion = this.labels?.notificacion == 'Activa' ? 1 : 2
    if(this.info!=undefined){
      if(this.info['proyectos']!=undefined){
        if(this.labels!=undefined){
          if(this.labels.title!="Nuevo Proyecto"){
            this.proyectos=JSON.parse(this.info['proyectos']);

          }
  
        }else{
          this.proyectos=JSON.parse(this.info['proyectos']);
  
        }
  
      }
    }

    if(this.option=="consultor"){
      this.ver=false;
      this.password= this.labels.contrasena;
      this.labels.contrasena=this.passwordBlock;

    }

  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
  loadProyectos(){
    let hash = {};
    this._service.getProyectos().subscribe( res=>{
      this.proyectos = res
      this.proyectos = this.proyectos.filter(function(current) {
        var exists = !hash[current['nombre-empresa']];
        hash[current['nombre-empresa']] = true;
        return exists;
        });
      return this.proyectos;
    })
  }
  loadConsultor(){
    this._service.getConsultores().subscribe( (res:any)=>{
      if(res.ok){
        this.consultores = res.data

      }else{

        this._service.mensajeError(res.msg);

      }

    })

  }
  showDelete(){
    this.modalService.open(this.borrar, { windowClass: 'animated fadeInDown', size: 'md' })
  }
  
  
  showEdit(){
    this.modalService.open(this.editar, { windowClass: 'animated fadeInDown', size: 'md' })
  }
  
  showCreate(){
    this.modalService.open(this.crear, { windowClass: 'animated fadeInDown', size: 'md' })
  }

  showEditSolicitude(consultores){
    //this.loadProyectos()
    //this.loadConsultor()
    this.consultores = consultores;
    this.modalService.open(this.editarSolicitud, { windowClass: 'animated fadeInDown', size: 'md' })
  }

  onSubmitCrear(e){    
    e.preventDefault()
    const form = e.target
    const data ={
        'proyecto': e.target[0].value,
        'nombre-empresa':  e.target[1].value,
      }
    this._service.crear('proyecto', data).subscribe( res=>{

      this.modalService.dismissAll();
      this.reloadCurrentRoute();
    })
  }

  onSubmitProject(e){
    e.preventDefault()
    let values = []
    const formElement = e.target
    for (const element of formElement) {
      values.push(element.value)
    }

    const data = {
      proyecto: values[2],
      consultor: Number.parseInt(values[5]),
      id: this.info['item_id']
    }

    this._service.actualizarSolicitud(data).subscribe( res=>{
      this.modalService.dismissAll();
      this.reloadCurrentRoute();
    })
  }

  onSubmitEditar(e){
    e.preventDefault()

    if(this.option=="consultor" && this.ver){
      this.password= (<HTMLInputElement>document.getElementById('pass')).value;
    }
    const dataObj = {
      proyecto: {
        'id': this.info['item_id'],
        'proyecto':  e.target[0].value,
        'nombre-empresa': e.target[1].value,
      },
      consultor: {
        'id': this.info['item_id'],
        'nombre': e.target[0].value,
        'email': e.target[1].value,
        'password': this.password,
      }
    }

    const data = dataObj[this.option]
    this._service.editar(this.option, data).subscribe( res=>{
      this.modalService.dismissAll()
      this.reloadCurrentRoute();
    })
  }
  
  borrarAceptar(){
    const data = this.info
    data['id'] = this.info['item_id']
  
     if(this.option==undefined){
      this._service.solicitudesActions(this.info, 'Eliminar').subscribe ( res=>{

        this.modalService.dismissAll()
        this.reloadCurrentRoute();
      })
    }
     else{
       this._service.borrar(this.option,data).subscribe ( res=>{

        this.modalService.dismissAll()
         this.reloadCurrentRoute();
       })
     }
  }

  verPassword(){
    this.ver=!this.ver;
    if(this.ver){
      this.labels.contrasena=this.password;
    }else{
      this.password= (<HTMLInputElement>document.getElementById('pass')).value;
      this.labels.contrasena=this.passwordBlock;
    }

  }
  
  }
  
