import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  breadcrumb: string = "Dashboard";

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  async navigateTo(sector) {

    const routes = ["ingreso-escaner", "generar-salida", "embarques-busqueda", "envio-externo", "envio-ruta", "embarques-consulta"];

    await this.router.navigateByUrl( routes[sector]);

  }

}
