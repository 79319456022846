<ng-template class="modal text-left" #ModalAgregar let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 class="negritaModal" id="myModalLabel23">Agregar Nuevas Imagenes</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeSoporteModal()">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- Formulario -->
    <div class="mt-2" >
      <form [formGroup]="soporteContact">
        <div class="form-body row px-2">
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Detalles</h3>
            </div>
            <div class="card">
              <div class="form-group">
                <label for="proyecto">Proyecto</label>
                <select id="proyecto" class="form-control" formControlName="projects"
                  [ngClass]="{ 'is-invalid': submitted && fProject.projects.errors }"required (change)="hideOption()">
                  <!--<option value="">Asignar Proyecto</option>-->
                  <option *ngFor="let project of projectsList" value="{{project.proyecto}}">{{project.proyecto}}</option>
                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && fProject.projects.errors"
                  class="invalid-feedback">
                  <div *ngIf="fProject.projects.errors.required">Este campo es requerido</div>
                </small>
              </div>
              <div class="form-group">
                <label for="tipoSoporte">Tipo de Soporte</label>
                <select id="tipoSoporte" class="form-control" formControlName="tipos"
                  [ngClass]="{ 'is-invalid': submitted && fProject.tipos.errors }" required>
                  <option selected hidden value="">Asignar Tipo</option>
                  <option *ngFor="let tipo of tiposList, index as i" [value]="tipo.id">{{tipo.tipo}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="Descripcion">Brindanos una Descripción Breve</label>
                <textarea id="Descripcion" rows="5" class="form-control" formControlName="description"
                  [ngClass]="{ 'is-invalid': submitted && fProject.description.errors }"
                  placeholder="Agrega una descripcion" required></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="card-header bg mb-1">
              <h3 class="card-title p-0 text-center txt">Imágenes</h3>
            </div>
            <ngx-dropzone  class="_dropzone" [accept]="'image/*'" [disabled]="isDisabled" (change)="SelectMultipleFile($event)">
              <ngx-dropzone-label>
                  <div class="dz-default dz-message">
                      <span class = "dz-default">Arrastre las imágenes aquí <br> (Maximo 10)</span>
                  </div>
              </ngx-dropzone-label>
            </ngx-dropzone>

          <div class="images__container">
            <div class="card-content" >
              <div class="card-body">
                <ngb-carousel [pauseOnHover]="true" interval="0">
                  <ng-template ngbSlide *ngFor="let f of files">
                    <div class="picsum-img-wrapper d-flex justify-content-center py-0 bg-dark">
                      <ngx-dropzone-image-preview class="carrusel_img" ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="MultiplefilesonRemove(f)">
                      </ngx-dropzone-image-preview>
                    </div>
                    <ngx-dropzone-label class="dropzone1">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ng-template>
                </ngb-carousel>
              </div>
            </div>

          </div>
          </div>
        </div>
        <div class="form-actions pr-2 text-right">
          <button *ngIf="!isFinished" (click)="loadImages()" class="btn clr-btn">
            Cargar Imágenes
          </button>
          <button *ngIf="isFinished" (click)="onSubmitProject()" class="btn clr-btn">
            <i class="la la-check"></i> Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>