import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.css']
})
export class WelcomeModalComponent implements OnInit {
  @ViewChild('InfoModal') modal: NgbModal
  constructor(
    private _modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  showModal() {
    this._modalService.open(this.modal, { windowClass: 'animated fadeInDown', size: 'lg' })
  }

}
