import { Router } from '@angular/router';
import { AdminModalsComponent } from './../../utils/modals/admin-modals/admin-modals.component';
import { ConectorApiService } from './../../../services/conector-api.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


@Component({
  selector: 'app-solicitudes-admin',
  templateUrl: './solicitudes-admin.component.html',
  styleUrls: ['./solicitudes-admin.component.css']
})
export class SolicitudesAdminComponent implements OnInit {
  @ViewChild(AdminModalsComponent) modals: AdminModalsComponent
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : true };

  public contenido:any;
  consultores: any
  proyectos: any
  public contenidoTodo:any;
  public selectedRow
  constructor(
    private _service: ConectorApiService,
    private _router: Router
  ) {
    this.contenido=[];
    this.contenidoTodo=[];
    let dataUserVerify = localStorage.getItem('perfil').toLowerCase()
    if (dataUserVerify != 'administrador') {
      _router.navigate(['/login']);
    }
    this.getSolicitudes();
  }

  ngOnInit(): void {
  }
  getSolicitudes(){

    this._service.getSolicitudes().subscribe( (res:any)=>{
      if(res.ok){
        let fechaConsulta = res.data.map( d=>{return { ...d, fechaconsulta: d['fecha-consulta']}});
        this.contenido = fechaConsulta
        this.contenidoTodo = this.contenido
      
        this._service.getConsultores().subscribe( (res:any)=>{
          if(res.ok){
            this.consultores = res.data
          }else{
            this._service.mensajeError(res.msg);
          }
        })
      }else{
        this._service.mensajeError(res.msg);
        this.contenido = undefined
        this.contenidoTodo = this.contenido
      }

    })
  }
  borrar(row){
    this.selectedRow = row
    this.modals.showDelete()
    this.getSolicitudes();
  }
  editar(row){
    if(!this.consultores){
      return;
    }
    this.selectedRow = row
    this.modals.showEditSolicitude(this.consultores)
    // this.getSolicitudes();
  }
  search(e) {
    if (e.target.value == "") {
      return (this.contenido = this.contenidoTodo);
    }
    const result = this.contenidoTodo.filter((x) =>
      x?.folio?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.cliente.title?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.proyecto?.toString()?.toLowerCase().includes(e.target.value.toString()?.toLowerCase()) || 
      x?.tipo?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.fechaconsulta?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) 
    );
    this.contenido = result;
  }

  filtrar(estatus){
    if (estatus == 'Todos') {
      this.contenido = this.contenidoTodo
    }
    else{
      this.contenido = this.contenidoTodo
      const newArray = []
      this.contenido.filter( row=>{
        const rowEstatus = this.removeAccents(row.estatus)
        console.log(rowEstatus, estatus)
        if(rowEstatus == estatus){
          newArray.push(row)
        }
      })
      this.contenido = newArray
    }
  }
  removeAccents(str){
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
  }
}
