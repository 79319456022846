<!-- fixed-top-->
<nav
  class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
  id="top-header" [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header">
      
      <ul class="nav navbar-nav flex-row">

         <!-- Remove position relative in responsive -->
        <li class="nav-item mobile-menu d-md-none mr-auto" >
          <a class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)">
            <i class="feather ft-menu font-large-1" ></i>
          </a>
        </li>

        <li class="nav-item"><a [routerLink]="['dashboard']" class="navbar-brand">
          <img class="brand-logo" alt="modern admin logo" src="../../../../assets/custom/ike-asistencia-footer.png">
          <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
          </a>
        </li>

        <li class="nav-item d-md-none">
          <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile" (click)="toggleNavbar($event)">
            <i class="la la-ellipsis-v"></i>
          </a>
        </li>
      </ul>
    </div>

    <div class="navbar-container content">

      <div class="collapse navbar-collapse show" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block">
            <!-- <a [routerLink]="" class="nav-link nav-menu-main menu-toggle hidden-xs" (click)="toggleFixMenu($event)">
              <i class="feather ft-menu"></i>
            </a> -->
          </li>
          <li class="nav-item d-none d-md-block">
            <!-- <a [routerLink]="" class="nav-link nav-link-expand" (click)="toggleFullScreen()" *ngIf ="maximize === 'on'">
              <i class="ficon feather ft-maximize"></i>
            </a> -->
          </li>
        </ul>

        <ul class="nav navbar-nav float-right">
          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span class="mr-1 text-bold-700" style="position:relative; bottom:12px">{{profile.name}}</span>
              <span class="mr-1 company-current">{{ company.name }}</span>

              <span class="avatar avatar-online">
                 <img src="assets/images/logo/user-bzr.png" alt="avatar" width="30">
                <i></i>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">

              <div class="dropdown-divider"></div>
              <a class="logout dropdown-item" [routerLink]="" (click)="logout()"><i class="feather ft-power"></i>Cerrar Sesión</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
