import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { SoporteformModalComponent } from './soporteform-modal/soporteform-modal.component';
import { CalificacionModalComponent } from './calificacion-modal/calificacion-modal.component';
import { AdminModalsComponent } from './admin-modals/admin-modals.component';
import { AsignarModalComponent } from './asignar-modal/asignar-modal.component';
import { CrearSoporteModalComponent } from './crear-soporte-modal/crear-soporte-modal.component';
import { EliminarImagenesComponent } from './eliminar-imagenes/eliminar-imagenes.component';
import { AgregarImagenesComponent } from './agregar-imagenes/agregar-imagenes.component';



@NgModule({
  declarations: [
    WelcomeModalComponent,
    SoporteformModalComponent,
    CalificacionModalComponent,
    AdminModalsComponent,
    AsignarModalComponent,
    CrearSoporteModalComponent,
    EliminarImagenesComponent,
    AgregarImagenesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WelcomeModalComponent,
    SoporteformModalComponent,
    CalificacionModalComponent,
    AdminModalsComponent,
    AsignarModalComponent,
    CrearSoporteModalComponent,
    EliminarImagenesComponent,
    AgregarImagenesComponent
  ]
})
export class ModalsModule { }
