<ng-template class="modal text-left" #ModalEliminar let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h6 id="myModalLabel21">Eliminar imagenes de la solicitud</h6>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- Basic form layout section start -->
      <div class="form-body">

        <div class="row justify-content-center align-items-center">

          <div *ngFor="let image of infoSolicitud['url-image']">
        
            <div class="col-md-12 col-md-3">
    
              <div class="card-title text-center">
                  <img [src]="image" alt="img25" height="120" width="250">
                </div>
                <h6 class="card-subtitle text-muted text-center font-small-3 pt-2 mb-1">
                  <span><input type="checkbox" (click)="cambiarChecked(image)"></span>
                </h6>
    
            </div>

          </div>

        </div>

    </div>

      
    </div>
    <div class="modal-footer">

      <button type="button" class="btn clr-btn" [disabled]="disabledEliminar" (click)="eliminarImagenes()">
        Aceptar
      </button>      
      <button type="button" class="ml-2 btn btn-danger" (click)="d('Cross click')">
        Cancelar
      </button>

    </div>
  </div>
</ng-template>