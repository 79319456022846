import { Router } from '@angular/router';
import { DetailsModalComponent } from './../../utils/modals/details-modal/details-modal.component';
import { ConectorApiService } from './../../../services/conector-api.service';
import { Component, Input, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


@Component({
  selector: 'app-solicitudes-soporte',
  templateUrl: './solicitudes-soporte.component.html',
  styleUrls: ['./solicitudes-soporte.component.css']
})
export class SolicitudesSoporteComponent implements AfterViewInit {
  
  public contenido:any;
  public contenidoActual:any;
  public selectedRow
  datatableData: any;
  // public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : true };
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };


  constructor(
    private modalService: NgbModal,
    private _service: ConectorApiService,
    private _router: Router) {
      this.contenido=[];
      this.contenidoActual=[];
      let dataUserVerify = localStorage.getItem('perfil').toLowerCase()
      if (dataUserVerify != 'soporte') {
        this._router.navigate(['/login']);
      }   
      this.actualizar();
    }

    ngAfterViewInit(): void {
      

  }

  search(e) {
    if (e.target.value == "") {
      return (this.contenidoActual = this.contenido);
    }
    const result = this.contenido.filter((x) =>
      x?.folio?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.cliente.title?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.proyecto?.toString()?.toLowerCase().includes(e.target.value.toString()?.toLowerCase()) || 
      x?.tipo?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
      x?.fechaconsulta?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) 
    );
    this.contenidoActual = result;
  }

filtrar(estatus){
  if (estatus == 'Todos') {
    this.contenidoActual = this.contenido
  }
  else{
    this.contenidoActual = this.contenido
    const newArray = []
    this.contenido.filter( row=>{
      const rowEstatus = this.removeAccents(row.estatus)
      console.log(rowEstatus, estatus)
      if(rowEstatus == estatus){
        newArray.push(row)
      }
    })
    this.contenidoActual = newArray
  }
}
removeAccents(str){
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
}

detalles(row, LargeModelContent){
  console.log(row)
      this.selectedRow = row
      localStorage.setItem('solicitud',JSON.stringify(row))
      this.modalService.open(LargeModelContent, { windowClass: 'animated fadeInDown', size: 'lg' })
  }

  archivar(row){
    this._service.solicitudesActions(row, 'Archivar').subscribe ( res=>{
      if(res['msg']=="Proceso Correcto"){
        console.log('solicitud archivada',res)
        this.actualizar();
      }
    })
  }

  actualizar(){
    this._service.getSolicitudes().subscribe( (res:any)=>{
      if(res.ok){
        let fechaConsulta = res.data.map( d=>{return { ...d, fechaconsulta: d['fecha-consulta']}});
        this.contenidoActual = fechaConsulta
        this.contenido = this.contenidoActual

      }else{
        this._service.mensajeError(res.msg);
        this.contenido = undefined
        this.contenido = this.contenidoActual
      }

    })
  }

  borrarAceptar(EliminarFormContent){
    console.log('eliminar')
    this._service.solicitudesActions(this.selectedRow, 'Eliminar').subscribe ( res=>{
      console.log('la solicutd ha sido eliminada')
      this.modalService.dismissAll()
    })
  }

  eliminar(EliminarFormContent, row) {
    this.selectedRow = row
    this.modalService.open(EliminarFormContent, { windowClass: 'animated fadeInDown' });
  }

}
