<!-- Modla eliminar -->
<ng-template class="modal text-left" #DeleteModal let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Eliminar</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body">
        <ng-container mCardBody>
          <div class="modal-body icon-alert mt-2 pb-2">
            <img src="../../../../assets/images/encuesta/eliminar.svg" alt="alerta" width="90">
          </div>
          <h3 class="icon-alert mb-3">¿Desea eliminar este {{option}}?</h3>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="submit" (click)="borrarAceptar()" class="btn btn-danger">Eliminar</button>
      </div>
  </div>
</ng-template>

<!-- Modal editar consultor -->
<ng-template class="modal text-left" #EditModal let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 id="myModalLabel23">{{labels.title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- titulos del modal en info -->
    <div class="p-2">
      <form (submit)="onSubmitEditar($event)">
        <div class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">{{labels.inputNombre}}</label>
          <input [value]="labels.nombre" class="form-control" required>
        </div>
        <div *ngIf="labels.inputCorreo != undefined" class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">{{labels.inputCorreo}}</label>
          <input [value]="labels.correo" class="form-control" required>
        </div>
        <div  *ngIf="labels.inputContrasena != undefined" class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">{{labels.inputContrasena}} 
            <i class="feather ft-eye cursor" (click)="verPassword()" *ngIf="ver"></i>
            <i class="feather ft-eye-off cursor" (click)="verPassword()" *ngIf="!ver" ></i>
          </label>
          <input [value]="labels.contrasena" class="form-control" id="pass" name="pass" required>
        </div>
        <div *ngIf="labels.labelEmpresa != undefined" class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">Empresa</label>
         <!-- <select [ngModel]="projectvalue" class="form-control">
            <option hidden selected>{{info['nombre-empresa']}}</option>
            <option [value]="proyecto['nombre-empresa']" *ngFor="let proyecto of proyectos">{{proyecto['nombre-empresa']}}</option>
          </select>-->
          <input [value]="info['nombre-empresa']" class="form-control" required>
        </div>

        <div *ngIf="labels.inputEstatus != undefined" class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">{{labels.inputEstatus}}</label>
          <select id="estatus" class="form-control" required  [value]="estatus">
            <option value="1">Activo</option>
            <option value="2">Inactivo</option>
          </select>

        </div>

        <div *ngIf="labels.inputNotificacion != undefined" class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">{{labels.inputNotificacion}}</label>
          <select id="estatus" class="form-control" required [value]= "notificacion">
            <option value="1">Activo</option>
            <option value="2">Inactivo</option>
          </select>
        </div>

        <div class="form-actions text-right">
          <button class="btn clr">
            Guardar
          </button>
        </div>
      </form>
  </div>
  </div>
</ng-template>

<!-- Modal crear -->
<ng-template class="modal text-left" #CreateModal let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 id="myModalLabel23">{{labels.title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- titulos del modal en info -->
    <div class="p-2">
      <form (submit)="onSubmitCrear($event)">
        <div class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">{{labels.input}}</label>
          <input [(ngModel)]="newInput" class="form-control" [placeholder]="labels.placeholder" required>
        </div>
        <div class="px-1 pb-2 row mrg" matchHeight="card">
          <label for="Descripcion">Empresa</label>
         <!-- <select class="form-control">
            <option [value]="proyecto['nombre-empresa']" *ngFor="let proyecto of proyectos">{{proyecto['nombre-empresa']}}</option>
          </select>-->
          <input class="form-control" required>
        </div>
        <div class="form-actions text-right">
          <button type="submit" class="btn clr">
            Crear
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- Modal editar solicitud -->
<ng-template class="modal text-left" #EditSolicitude let-c="close" let-d="dismiss">
  <div class="modal-lg">
    <div class="modal-header">
      <h4 id="myModalLabel23">Editar Solicitud</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="icon-close-md" aria-hidden="true">&times;</span>
      </button>

    </div>
    <!-- titulos del modal en info -->
    <div class="p-2 row">
      <form class="pl-2 w-100" (submit)="onSubmitProject($event)">
        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <label for="Descripcion">Folio</label>
          <input disabled [value]="info['folio']" class="form-control">
        </div>

        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <label for="Descripcion">Cliente</label>
          <input disabled [value]="info.cliente['title']" class="form-control">
        </div>

        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <label for="Descripcion">Proyecto</label>
          <select [ngModel]="projectvalue" class="form-control">
            <option hidden selected>{{info['proyecto']}}</option>
            <option *ngFor="let proyecto of proyectos" [value]="proyecto['proyecto']">{{proyecto['proyecto']}}</option>
          </select>
        </div>

        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <label for="Descripcion">Tipo</label>
          <input disabled [value]="info['tipo']" class="form-control">
        </div>
        
        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <div class="col-12" style="padding-left: 0px;">
            <label for="Descripcion">Descripción</label>
          </div>
          <div class="col-12" style="background-color: #ECEFF1; border: 1px solid #cacfe7;">
            <div [innerHTML]="info['descripcion']"></div>  
          </div>
        </div>

        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <label for="Descripcion">Fecha</label>
          <input disabled [value]="info['fecha-consulta'] | date:'dd-MM-YYYY'" class="form-control">
        </div>

        <div class="px-1 pb-2 row mrg col-12" matchHeight="card">
          <label for="Descripcion">Consultor</label>
          <select class="form-control">
            <option hidden selected>{{info.consultor!=undefined?info.consultor['title']:'No Asignado'}}</option>
            <option *ngFor="let consultor of consultores" [value]="consultor['item_id']">{{consultor['nombre']}}</option>
          </select>
        </div>
        <div class="form-actions text-right px-2">
          <button class="btn clr">
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
